function CookieBar (element) {
    WebPageComponent.call(this, element);

    this.cookieName = element.dataset.Name;
    this.cookiePath = element.dataset.Path;

    this.determineElements = function () {
        var query = new DomQuery(this.element);

        this.button = query.getDescendant(WithClass("Button"));

        this.expanded = new HtmlClassSwitch(this.element, "Expanded");
        this.expanded.setStatus(true);
    }

    this.buttonClicked = function (event) {
        document.cookie = this.cookieName + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=" + this.cookiePath;
        this.expanded.setStatus(false);
        getEvent(event).stopHandling();
    }

    this.attachEventHandlers = function () {
        var object = this;

        this.button.onclick = function (event) { object.buttonClicked(getEvent(event)); };
    }

    this.determineElements();
    this.attachEventHandlers();
}

interactivityRegistration.register("CookieBar", function (element) { return new CookieBar(element); });

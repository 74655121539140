var RefreshMode = new Enumeration(["OnRequest", "ForSiblings", "Recurring"]);
var RenderScope = new Enumeration(["None", "Toolbar", "Body", "Component", "Complete"]);
var ControlMode = new Enumeration(["display", "edit"]);

function maximumRenderScope(left, right) {
    if (left === RenderScope.Complete || right === RenderScope.Complete)
        return RenderScope.Complete;
    if (left === RenderScope.Component || right === RenderScope.Component)
        return RenderScope.Component;
    else if (left === RenderScope.Body || right === RenderScope.Body)
        return RenderScope.Body;
    else if (left === RenderScope.Toolbar || right === RenderScope.Toolbar)
        return RenderScope.Toolbar;
    else
        return RenderScope.None;
}

function ComponentWithName(name) {
    return function (component) { return component.getName() === name; }
}

function ComponentWithClass(class_) {
    return function (component) { return component instanceof class_; }
}

function WebPageComponent(element) {
    this.createToggleButton = function(element, subject, class_) {
        element.onclick = function (event) {
            var classSwitch = new HtmlClassSwitch(subject, class_);
            classSwitch.toggle();

            if (!classSwitch.getStatus())
                subject.clickOutsideListener = connectClickOutsideListener(subject, function (event) {element.onclick(event)})
            else if (subject.clickOutsideListener !== undefined)
                removeClickOutsideListener(subject.clickOutsideListener);
        }
    }

    this.determineProperties = function() {
        var component = this;

        if (this.element.dataset.RefreshMode !== undefined) {
            this.refreshMode = RefreshMode.fromText(this.element.dataset.RefreshMode);

            if (this.refreshMode === RefreshMode.Recurring) {
                this.refreshInterval = this.element.dataset.RefreshInterval;

                component.refreshProcessId = window.setInterval(
                    function() {
                        if (document.body.contains(component.element))
                            component.refresh();
                        else
                            window.clearInterval(component.refreshProcessId);
                    },
                    this.refreshInterval
                );
            }
        }

        if (this.element.dataset.Mode !== undefined)
            this.mode = ControlMode.fromText(this.element.dataset.Mode);
        else
            this.mode = ControlMode.edit;
    }

    this.fireSelectionChangedEvent = function() {
        if (this.onSelectionChanged !== null)
            this.onSelectionChanged(this);
    }

    this.focus = function() {
    }

    this.isFocusable = function() {
        return this.mode === ControlMode.edit;
    }

    this.getName = function () {
        return "";
    }

    this.getValue = function () {
        return null;
    }

    this.getAncestor = function (condition) {
        var result = null;
        var current = this;

        while (result === null && current !== null) {
            if (condition(current))
                result = current;
            else
                current = current.parentComponent;
        }

        return result;
    }

    this.getDescendant = function (condition) {
 	    // TODO: We probably want to use childComponents to find the appropriate descendant.
        var query = new DomQuery(this.element);
        var element = query.getDescendant(
            function (element) {
                return element.component !== undefined && condition(element.component)
            }
        );

        if (element !== null)
            return element.component;
        else
            return null;
    }

    this.bind = function () {
    }

    this.handleEvent = function (event) {
    }

    this.addEventListener = function (event, handler) {
        // Override in specific descendants when the root element is not an input element
        this.element.addEventListener(event, handler);
    }

    this.refresh = function () {
    }

    this.element = element;
    this.visible = true;
    this.client = new HttpClient();
    this.onSelectionChanged = null;

    this.parentComponent = null;
    this.childComponents = new Array();

    this.determineProperties();
}

function connectClickOutsideListener(element, function_) {
    var listener = function (event) {
        var query = new DomQuery(getEvent(event).getSource());

        if (!query.hasAncestor(element))
            function_(event);
    };
        
    var htmlElement = document.getElementsByTagName("html")[0];
    htmlElement.addEventListener("click", listener, true);
    
    return listener;
}

function removeClickOutsideListener(listener) {
    var htmlElement = document.getElementsByTagName("html")[0];
    htmlElement.removeEventListener("click", listener, true);
}

function MultipleSelectButtons(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function(event, handler) {
        this.input.addEventListener(event, handler);
    }

    this.attachHandlers = function() {
        for (var button of this.buttons) {
            button.element.addEventListener("click", this.createToggleButtonHandler(button));
            button.element.addEventListener("keydown", this.createKeyHandler(button));
        }
    }

    this.createKeyHandler = function(button) {
        var object = this;

        return function (event) {
            return object.handleKey(button, event);
        }
    }

    this.createToggleButtonHandler = function(button) {
        var object = this;
        
        return function(event) { 
            object.toggle(button); 
            return true; 
        }
    }

    this.focus = function() {
        this.buttons[0].element.focus();
    }

    this.focusNext = function(button) {
        var target = button.element.nextSibling;
        
        if (target !== null && target.tagName === "BUTTON")
            target.focus();
    }

    this.focusPrevious = function(button) {
        var target = button.element.previousSibling;
        
        if (target !== null && target.tagName === "BUTTON")
            target.focus();
    }

    this.getName = function() {
        return this.input.name;
    }

    this.getValue = function() {
        return this.input.value;
    }

    this.handleKey = function(button, event) {
        var result = false;

        if (event.code === "Space") {
            event.preventDefault();
        }
        else if (event.code === "ArrowLeft") {
            this.focusPrevious(button);
            event.preventDefault();
        }
        else if (event.code === "ArrowRight") {
            this.focusNext(button);
            event.preventDefault();
        }
        else if (event.code === "Home") {
            this.buttons[0].element.focus();
            event.preventDefault();
        }
        else if (event.code === "End") {
            this.buttons[this.buttons.length - 1].element.focus();
            event.preventDefault();
        }
        else if (event.ctrlKey && event.code === "KeyA") {
            this.selectAll();
            event.preventDefault();
        }

        return result;
    }

    this.initialize = function() {
        var query = new DomQuery(this.element);
        var buttons = query.getDescendants(WithTagName("BUTTON"));

        for (var button of buttons) 
            this.buttons.push(new MultipleSelectButton(button));

        this.input = query.getDescendant(WithTagName("INPUT"));
    }

    this.recalculate = function() {
        var values = new Array();

        for (var button of this.buttons)
            if (button.selected)
                values.push(button.value);

        this.setValue(values.join(","));
    }

    this.selectAll = function() {
        for (var button of this.buttons)
            if (!button.selected)
                button.toggle();

        this.recalculate();
    }

    this.setValue = function(value) {
        this.input.value = value;

        this.input.dispatchEvent(new Event("input"));
        this.input.dispatchEvent(new Event("change"));
    }

    this.toggle = function(button) {
        button.toggle();
        this.recalculate();
    }

    if (this.mode === ControlMode.edit) {
        this.buttons = new Array();
        this.initialize();
        this.attachHandlers();

        if (this.buttons.length > 0)
            this.buttons[0].element.tabIndex = 0;
    }
}

function MultipleSelectButton(element) {
    this.toggle = function() {
        this.selected = !this.selected;

        this.classSwitch.setStatus(this.selected);
        this.element.setAttribute("aria-selected", this.selected);
    }
    
    this.element = element;
    this.element.tabIndex = -1;

    this.value = this.element.dataset.Value;
    this.selected = element.classList.contains("Selected");

    this.classSwitch = new HtmlClassSwitch(this.element, "Selected");
    this.selected = this.classSwitch.getStatus();
}

interactivityRegistration.register("MultipleSelectButtons", function (element) { return new MultipleSelectButtons(element); });

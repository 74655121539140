function DropZone(element) {
    WebPageComponent.call(this, element);

    this.createDragEndOutsideListener = function () {
        var object = this;

        return function (event) {
            object.draggingOutside.setStatus(false);
            object.draggingInside.setStatus(false);
        };
    }

    this.createDragEnterOutsideListener = function () {
        var object = this;

        return function (event) {
            var query = new DomQuery(getEvent(event).getSource());

            if (!query.hasAncestor(object.element))
                object.draggingInside.setStatus(false);
        };
    }

    this.createDragLeaveOutsideListener = function () {
        var object = this;

        return function (event) {
            if (event.fromElement == null)
                object.draggingOutside.setStatus(false);
        };
    }

    this.createDragOverOutsideListener = function () {
        var object = this;

        return function (event) {
            var event = getEvent(event);
            var query = new DomQuery(getEvent(event).getSource());

            if (!query.hasAncestor(object.element)) {
                event.preventDefault();

                if (event.dataTransfer.dropEffect != "move")
                    event.dataTransfer.dropEffect = "none";

                object.draggingOutside.setStatus(true);
            }
            else
                event.dataTransfer.dropEffect = "move";
        };
    }

    this.dragEnter = function (event) {
        this.draggingInside.setStatus(true);
        this.draggingOutside.setStatus(false);
    }

    this.dragOver = function (event) {
        event.preventDefault();
    }

    this.drop = function (event) {
        var object = this;
        event.preventDefault();

        var element = document.getElementsByTagName("html")[0];
        var dragEndEvent = document.createEvent("DragEvent");
        dragEndEvent.initEvent("dragend", false, false);

        element.dispatchEvent(dragEndEvent)

        if (this.canDrop(event))
            this.handleDrop(event);
    }

    this.initializeDropZone = function() {
        var object = this;

        if (this.isEnabled()) {
            this.draggingInside = new HtmlClassSwitch(this.element, "DraggingInside");
            this.draggingInside.setStatus(false);

            this.draggingOutside = new HtmlClassSwitch(this.element, "DraggingOutside");
            this.draggingOutside.setStatus(false);

            this.dragEnterOutsideListener = this.createDragEnterOutsideListener();
            this.dragOverOutsideListener = this.createDragOverOutsideListener();
            this.dragLeaveOutsideListener = this.createDragLeaveOutsideListener();
            this.dragEndOutsideListener = this.createDragEndOutsideListener();

            this.element.ondragenter = function (event) { object.dragEnter(getEvent(event)); };
            this.element.ondragover = function (event) { object.dragOver(getEvent(event)); };
            this.element.ondrop = function (event) { object.drop(getEvent(event)); };

            var htmlElement = document.getElementsByTagName("html")[0];
            htmlElement.addEventListener("dragenter", this.dragEnterOutsideListener, true);
            htmlElement.addEventListener("dragover", this.dragOverOutsideListener, true);
            htmlElement.addEventListener("dragleave", this.dragLeaveOutsideListener, true);
            htmlElement.addEventListener("dragend", this.dragEndOutsideListener, true);
        }
    }

    this.isEnabled = function() {
        return true;
    }
}
class Application {
    constructor() {
        if ('serviceWorker' in navigator) {
            this.uploadCenter = new UploadCenter();
            this.startServiceWorker();

            setInterval(
                () => {
                    this.assureServiceWorkerRunning();
                },
                5000
            );
        }
    }

    async assureServiceWorkerRunning() {
        const serviceWorker = await navigator.serviceWorker.getRegistration();

        if (serviceWorker.active === null) {
            this.startServiceWorker();

            this.uploadCenter.connect(navigator.serviceWorker);
        }
        else
            serviceWorker.active.postMessage([]);
    }

    startServiceWorker() {
        const url = new URL("../ServiceWorker.js", document.scripts[0].src);

        navigator.serviceWorker.addEventListener('controllerchange', (event) => this.uploadCenter.connect(navigator.serviceWorker));
        navigator.serviceWorker.register(url.toString())
            .then(() => this.uploadCenter.connect(navigator.serviceWorker));
    }
}

window.onload = () => {
    interactivityRegistration.attach(document.body);
    new Application();
}

function DropDown(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function(event, handler) {
        if (this.mode === ControlMode.edit)
            this.input.addEventListener(event, handler);
    }

    this.attachHandlers = function() {
        this.button.addEventListener("click", (event) => { this.toggleForm(); });
        this.label.addEventListener("click", (event) => { this.toggleForm(); });

        for (const option of this.options) {
            option.element.addEventListener("click", this.createSelectOptionHandler(option));
            option.element.addEventListener("keydown", this.createOptionKeyHandler(option));
        }

        this.element.addEventListener("keydown", this.createKeyHandler());
    }

    this.close = function() {
        this.dropDownToggle.setStatus(false);
        removeClickOutsideListener(this.clickOutsideListener);
    }

    this.createKeyHandler = function() {
        return (event) => {
            return this.handleKey(event);
        }
    }

    this.createOptionKeyHandler = function(option) {
        return (event) => {
            return this.handleOptionKey(option, event);
        }
    }

    this.createSelectOptionHandler = function(option) {
        return (event) => {
            this.select(option);
            this.close();
            this.focus();

            return true;
        }
    }

    this.determineElements = function() {
        const query = new DomQuery(this.element);

        this.dropDown = query.getChild(WithClass("Options"));
        this.dropDownToggle = new HtmlClassSwitch(this.dropDown, "Expanded");

        this.button = query.getChild(WithTagName("BUTTON"));
        this.button.tabIndex = -1;

        this.label = query.getChild(WithClass("Label"));
        this.input = query.getChild(WithTagName("INPUT"));

        const options = new DomQuery(this.dropDown).getChildren(WithClass("Option"));

        for (const option of options)
            this.options.push(new DropDownOption(option));
    }

    this.focus = function() {
        this.element.focus();
    }

    this.focusNext = function(option) {
        const target = option.element.nextSibling;

        if (target !== null && target.classList.contains("Option"))
            target.focus();
    }

    this.focusPrevious = function(option) {
        const target = option.element.previousSibling;

        if (target !== null && target.classList.contains("Option"))
            target.focus();
    }

    this.getName = function () {
        if (this.mode === ControlMode.edit)
            return this.input.name;
        else if (this.mode === ControlMode.display)
            return this.element.dataset.Name;
        else
            throw "Unknown control mode: " + this.mode;
    }

    this.getValue = function () {
        if (this.mode === ControlMode.edit)
            return this.input.value;
        else if (this.mode === ControlMode.display)
            return this.element.dataset.Value;
        else
            throw "Unknown control mode: " + this.mode;
    }

    this.handleKey = function(event) {
        if (event.ctrlKey && event.code === "Space") {
            this.toggleForm();
            event.stopPropagation();
        }
    }

    this.handleOptionKey = function(option, event) {
        if (event.code === "Space" || event.code === "Enter") {
            this.select(option);
            this.close();
            this.focus();

            event.preventDefault();
        }
        else if (event.code === "Escape") {
            this.close();
            this.focus();

            event.stopPropagation();
        }
        else if (event.code === "ArrowUp") {
            this.focusPrevious(option);
            event.preventDefault();
        }
        else if (event.code === "ArrowDown") {
            this.focusNext(option);
            event.preventDefault();
        }
        else if (event.code === "Home") {
            this.options[0].focus();
            event.preventDefault();
        }
        else if (event.code === "End") {
            this.options[this.options.length - 1].focus();
            event.preventDefault();
        }
    }

    this.isOpen = function() {
        return this.dropDownToggle.getStatus();
    }

    this.open = function() {
        this.dropDownToggle.setStatus(true);
        this.dropDown.scrollIntoView({block: "nearest", inline: "nearest"});
        this.clickOutsideListener = connectClickOutsideListener(this.element, (event) => { this.toggleForm(); });

        const selected = this.options.find(element => element.selected);

        if (selected !== undefined)
            selected.focus();
        else
            this.options[0].focus();
    }

    this.recalculate = function() {
        this.label.innerHTML = "";

        for (const option of this.options) {
            if (option.value === this.input.value) {
                option.selected = true;
                this.label.innerHTML = option.element.innerHTML;
            }
            else
                option.selected = false;
        }
    }

    this.setValue = function(value) {
        this.input.value = value;
        this.recalculate();

        this.input.dispatchEvent(new Event("change"));
    }

    this.select = function(option) {
        if (!option.selected)
            this.setValue(option.value);
    }

    this.toggleForm = function() {
        if (this.isOpen())
            this.close();
        else
            this.open();
    }

    this.element.tabIndex = 0;
    this.options = new Array();

    if (this.mode === ControlMode.edit) {
        this.determineElements();
        this.recalculate();
        this.attachHandlers();
    }
}

function DropDownOption(element) {
    this.focus = function() {
        this.element.focus();
    }

    Object.defineProperty(this, 'selected', {
        get: function() {
            return this.classSwitch.getStatus();
        },
        set: function(selected) {
            this.classSwitch.setStatus(selected);
            this.element.setAttribute("aria-selected", selected);
        }
    });

    Object.defineProperty(this, 'value', {
        get: function() {
            return this.element.dataset.Value;
        }
    });

    this.element = element;
    this.element.tabIndex = -1;

    this.classSwitch = new HtmlClassSwitch(this.element, "Selected");
}

interactivityRegistration.register("DropDown", function (element) { return new DropDown(element); });

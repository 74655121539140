function PercentageField(element) {
    WebPageComponent.call(this, element);

    this.build = function() {
        var progressBar = new ProgressBar(1);
        this.element.appendChild(progressBar.element);
        progressBar.setProgress([ this.getValue() ], 0);
    }

    this.getValue = function() {
        var result;

        if (this.element.dataset.Mode === "display")
            result = 100 * parseFloat(this.element.dataset.Value);
        else
            result = 0;

        return result;
    }

    if (this.mode === ControlMode.display && this.element.dataset.Value !== undefined)
        this.build();
}

interactivityRegistration.register("PercentageField", function (element) { return new PercentageField(element); });

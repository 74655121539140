const State = new Enumeration(["Initial", "Uploading", "Error", "Successful", "Aborted", "Forbidden"]);

class FileStore {
  constructor() {
    this.databaseName = "FileStore"
    this.fileStoreName = "files";

    this.database = null;

    const version = 1;
    const request = indexedDB.open(this.databaseName, version);

    request.onsuccess = (event) => {
      this.database = event.target.result;
    }

    request.onupgradeneeded = (event) => {
      const database = event.target.result;

      const fileStore = database.createObjectStore(this.fileStoreName, { keyPath: "id", autoIncrement: true });
      fileStore.createIndex("name", "name", { unique: false });
      fileStore.createIndex("state", "state", { unique: false });
      fileStore.createIndex("target", "target", { unique: false });
    }
  }

  add(file) {
    return new Promise((resolve, reject) => {
      let result;

      const transaction = this.database.transaction(this.fileStoreName, "readwrite");
      const store = transaction.objectStore(this.fileStoreName);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = store.add(file);
      request.onsuccess = () => {
        result = request.result;
      }
    });
  }

  delete(key) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction(this.fileStoreName, "readwrite");
      const store = transaction.objectStore(this.fileStoreName);

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      store.delete(key);
    });
  }

  deleteMatches(indexName, value) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction(this.fileStoreName, "readwrite");
      const store = transaction.objectStore(this.fileStoreName);
      const index = store.index(indexName);
      const range = IDBKeyRange.only(value);

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = index.openCursor(range);

      request.onsuccess = () => {
        const cursor = request.result;

        if (cursor) {
          cursor.delete();
          cursor.continue();
        }
      }
    });
  }

  get(key) {
    return new Promise((resolve, reject) => {
      let result;

      const transaction = this.database.transaction(this.fileStoreName, "readonly");
      const store = transaction.objectStore(this.fileStoreName);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = store.get(key);
      request.onsuccess = () => {
        result = request.result;
      }
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      let result;

      const transaction = this.database.transaction(this.fileStoreName, "readonly");
      const store = transaction.objectStore(this.fileStoreName);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = store.getAll();
      request.onsuccess = () => {
        result = request.result;
      }
    });
  }

  update(file) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction(this.fileStoreName, "readwrite");
      const store = transaction.objectStore(this.fileStoreName);

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      store.put(file);
    });
  }
}

function FormFeedback (element) {
    WebPageComponent.call(this, element);

    this.determineElements = function () {
        var query = new DomQuery(this.element);

        this.collapsed = new HtmlClassSwitch(this.element, "collapsed");
        this.closeButton = query.getDescendant(WithClass("close"));
    }

    this.close = function () {
        this.collapsed.setStatus(true);
    }

    this.attachEventHandlers = function () {
        var object = this;

        this.closeButton.onclick = function (event) { object.close(getEvent(event)); };
    }

    this.determineElements();
    this.attachEventHandlers();
}

function FeedbackForm (element) {
    WebPageComponent.call(this, element);

    this.determineElements = function () {
        var query = new DomQuery(this.element);

        this.expanded = new HtmlClassSwitch(this.element, "Expanded");
        this.expanded.setStatus(false);

        this.label = query.getDescendant(WithClass("Label"));
    }

    this.toggleForm = function () {
        this.expanded.toggle();
    }

    this.attachEventHandlers = function () {
        var object = this;

        this.label.onclick = function (event) { object.toggleForm(getEvent(event)); };
    }

    this.label;
    this.determineElements();
    this.attachEventHandlers();
}

interactivityRegistration.register("feedback", function (element) { return new FormFeedback(element); });
interactivityRegistration.register("FeedbackForm", function (element) { return new FeedbackForm(element); });

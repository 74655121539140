var ToolbarState = new Enumeration(["None", "Warning", "Error", "Submitted"]);

function Toolbar(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        var object = this;

        connectClickOutsideListener(this.element, function() {
            object.successful.setStatus(false);
        });

        if (this.actionForm !== null)
            this.actionForm.onsubmit = function (event) { return false; };

        for (var index = 0; index < this.groups.length; index++)
            new ActionGroup(this.groups[index]);
    }

    this.createKeyHandler = function() {
        var object = this;

        return function(event) {
            if (event.code === "ArrowLeft") {
                object.focus(object.focusedItemIndex - 1);

                event.stopPropagation();
                event.preventDefault();
            }
            else if (event.code === "ArrowRight") {
                object.focus(object.focusedItemIndex + 1);
                
                event.stopPropagation();
                event.preventDefault();
            }
            else if (event.code === "Home") {
                object.focus(0);
                
                event.stopPropagation();
                event.preventDefault();
            }
            else if (event.code === "End") {
                object.focus(object.buttons.length - 1);
                
                event.stopPropagation();
                event.preventDefault();
            }
        }
    }

    this.determineElements = function() {
        let query = new DomQuery(this.element);

        this.groups = query.getDescendants(WithClass("ActionGroup"));
        this.iconBar = query.getChild(WithClass("IconBar"));
        this.actionForm = query.getChild(WithClass("ActionForm"));
        this.actionBar = query.getChild(WithClass("ActionBar"));
        this.actionFeedback = query.getDescendant(WithClass("ActionFeedback"));

        query = new DomQuery(this.actionBar)
        this.menu = query.getChild(WithClass("Menu"));
        this.objectActions = query.getDescendants(WithClass("ObjectAction"));

        this.form = new DomQuery(this.actionForm).getChild(WithTagName("FORM"));

        this.initializeButtons();
    }

    this.focus = function(index) {
        if (index >= 0 && index < this.buttons.length) {
            this.buttons[this.focusedItemIndex].tabIndex = -1;
            this.buttons[index].tabIndex = 0;
            this.buttons[index].focus();
        
            this.focusedItemIndex = index;
        }
    }

    this.handleAction = function(action) {
        const objectAction = this.objectActions.find(element => element.dataset.Name === action);

        if (objectAction !== undefined)
            objectAction.component.open();
        else
            this.parentComponent.sendCommandRequest("<Action Name=\"" + action + "\"/>");
    }

    this.handleEvent = function(event) {
        if (event instanceof MenuActionEvent) { 
            this.handleAction(event.action);
            event.handled = true;
        }
    }

    this.initializeButtons = function() {
        this.buttons = new Array();
        const buttons = new DomQuery(this.iconBar).getDescendants(function(element) {return element.tagName === "BUTTON" &! element.disabled});

        for (const button of buttons)            
            this.buttons.push(button);

        for (const button of this.buttons) {
            button.tabIndex = -1;
            button.addEventListener("keydown", this.createKeyHandler());
        }

        if (this.buttons.length > 0)
            this.buttons[0].tabIndex = 0;

        this.focusedItemIndex = 0;
    }

    this.replace = function(toolbar, persistForm) {
        interactivityRegistration.detach(this.element);
        const query = new DomQuery(toolbar);
        const iconBar = query.getChild(WithClass("IconBar"));
        const actionBar = query.getChild(WithClass("ActionBar"));
        const actionForm = query.getChild(WithClass("ActionForm"));
        const actionFeedback = query.getChild(WithClass("ActionFeedback"));
        const form = new DomQuery(actionForm).getChild(WithTagName("FORM"));

        this.element.replaceChild(iconBar, this.iconBar);
        this.element.replaceChild(actionBar, this.actionBar);
        this.element.replaceChild(actionFeedback, this.actionFeedback);

        if (!persistForm || this.form === null || form === null || this.form.id !== form.id)
            this.element.replaceChild(actionForm, this.actionForm);

        this.updateState();
        this.determineElements();
        this.attachHandlers();

        interactivityRegistration.attach(this.element);
    }

    this.updateState = function() {
        this.successful.setStatus(this.state === ToolbarState.Submitted);
        this.warning.setStatus(this.state === ToolbarState.Warning);
        this.error.setStatus(this.state === ToolbarState.Error);

        this.state = ToolbarState.None;
    }

    this.state = ToolbarState.None;

    this.warning = new HtmlClassSwitch(this.element, "Warning");
    this.successful = new HtmlClassSwitch(this.element, "Successful");
    this.error = new HtmlClassSwitch(this.element, "Error");

    this.determineElements();
    this.attachHandlers();

    this.element.component = this;
}

function IntegerField(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function (event, handler) {
        this.input.addEventListener(event, handler);
    }
    
    this.determineElements = function() {
        this.input = this.element;
    }

    this.focus = function() {
        this.input.focus();
    }

    this.getName = function() {
        if (this.input !== null)
            return this.input.name
        else
            return null;
    }

    this.getValue = function() {
        return this.input.value;
    }

    this.setValue = function(value) {
        this.input.value = value;
    }
    
    this.determineElements();
}

interactivityRegistration.register("IntegerField", function (element) { return new IntegerField(element); });

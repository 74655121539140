function CheckBoxField(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function (event, handler) {
        this.input.addEventListener(event, handler);
    }

    this.createKeyListener = function() {
        var object = this;

        return function(event) {
            if (event.code === "Enter" || event.code === "Space") {
                object.setValue(!object.getValue());
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    this.getName = function () {
        return this.name;
    }

    this.getValue = function () {
        if (this.input.indeterminate)
            return null
        else 
            return this.input.checked;
    }

    this.setValue = function(value) {
        this.input.indeterminate = value === null;

        if (!this.input.indeterminate)
            this.input.checked = value;

        this.input.dispatchEvent(new Event("change"));
    }

    this.input = new DomQuery(this.element).getDescendant(WithTagName("INPUT"));

    if (this.input !== null) {
        if (!this.input.disabled) {
            this.element.tabIndex = 0;
            this.element.addEventListener("keydown", this.createKeyListener());
        }

        this.name = this.input.name;
    }
}

interactivityRegistration.register("CheckBoxField", function (element) { return new CheckBoxField(element); });

function SignatureField(element) {
  WebPageComponent.call(this, element);

  this.attachHandlers = function () {
    var object = this;

    this.canvas.addEventListener("mousedown", function(event) { object.startDraw(object.getMousePosition(event)); }, false);
    this.canvas.addEventListener("mouseup", function(event) { object.stopDraw() }, false);
    this.canvas.addEventListener("mousemove", function(event) { object.draw(object.getMousePosition(event)); }, false);
    
    this.canvas.addEventListener("touchstart", function(event) { object.startDraw(object.getTouchPosition(event)); }, false);
    this.canvas.addEventListener("touchend", function(event) { object.stopDraw() }, false);
    this.canvas.addEventListener("touchmove", function(event) { object.draw(object.getTouchPosition(event)); }, false);

    this.clearButton.addEventListener("click", function(event) { object.clear(); }, false);

    document.body.addEventListener("touchstart", function (event) { if (event.target === object.canvas) { event.preventDefault(); }}, false);
    document.body.addEventListener("touchmove", function (event) { if (event.target === object.canvas) { event.preventDefault(); }}, false);
    document.body.addEventListener("touchend", function (event) { if (event.target === object.canvas) { event.preventDefault(); }}, false);
  }

  this.build = function() {
    this.canvas = document.createElement("canvas");
    this.canvas.height = 200;
    this.canvas.width = 800;

    this.element.appendChild(this.canvas);

    if (this.mode === ControlMode.edit) {
      this.input = document.createElement("input");
      this.input.setAttribute("type", "hidden");
      this.input.setAttribute("name", this.element.dataset.Name);

      this.element.appendChild(this.input);
  
      this.clearButton = document.createElement("button");
      this.clearButton.setAttribute("type", "button");

      this.element.appendChild(this.clearButton);
    }
  }

  this.clear = function() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.input.value = "";
  }

  this.draw = function(position) {
    if (this.isDrawing && this.previousPosition !== null) {
      this.context.beginPath();
      this.context.moveTo(this.previousPosition.x, this.previousPosition.y);
      this.context.lineTo(position.x, position.y);
      this.context.stroke();
      
      this.previousPosition = position;
      this.input.value = this.canvas.toDataURL().split(';base64,')[1];
    }
  }

  this.getPosition = function(x, y) {
    var rectangle = this.canvas.getBoundingClientRect();
    var scaleX = this.canvas.width / this.canvas.clientWidth;
    var scaleY = this.canvas.height / this.canvas.clientHeight;

    return {
      x: scaleX * (x - rectangle.left),
      y: scaleY * (y - rectangle.top)
    };
  }

  this.getMousePosition = function(event) {
    return this.getPosition(event.clientX, event.clientY);
  }

  this.getTouchPosition = function(event) {
    return this.getPosition(event.touches[0].clientX, event.touches[0].clientY);
  }

  this.initialize = function() {
    this.context = this.canvas.getContext("2d");
    this.context.strokeStyle = "#404040";
    this.context.lineWidth = 2;

    if (this.element.dataset.Value.length >= 0) {
      var object = this;

      var image = new Image();
      image.addEventListener("load", function() { object.context.drawImage(image, 0, 0); }, false);
      image.src = "data:image/png;base64," + this.element.dataset.Value;
    }

    this.isDrawing = false;
    this.previousPosition = null;
  }

  this.stopDraw = function() {
    this.isDrawing = false;
  }

  this.startDraw = function(position) {
    this.isDrawing = true;
    this.previousPosition = position;
  }

  this.build();
  this.initialize();

  if (this.mode === ControlMode.edit)
    this.attachHandlers();
}

interactivityRegistration.register("Signature", function (element) { return new SignatureField(element); });

function RecordSetField(element) {
    WebPageComponent.call(this, element);

    this.insertRow = function () {
        this.maximumRowIndex++;

        const rowIndex = this.maximumRowIndex;
        const xmlRequest = newXmlRequest();
        const commands = "<insertRow index=\"" + rowIndex + "\"/>";

        xmlRequest.open("POST", this.URI, true);
        xmlRequest.setRequestHeader("Content-Type", "application/xml");
        xmlRequest.onreadystatechange = () => { this.handleInsertRowResponse(xmlRequest, rowIndex); }
        xmlRequest.send(commands);
    };

    this.removeRow = function (row) {
        this.rowIndices.splice(row.rowIndex - 1, 1);
        this.updateRowIndicesField();

        row.parentNode.removeChild(row);
        this.valueChanged();
    }

    this.updateRowIndicesField = function () {
        this.rowIndicesField.value = this.rowIndices.join(", ");
    };

    this.valueChanged = function() {
        if (this.rowIndicesField.onchange !== null)
            this.rowIndicesField.onchange();
    }

    this.handleInsertRowResponse = function (xmlRequest, rowIndex) {
        if (xmlRequest.readyState == 4) {
            const rowContainer = document.createElement("div");
            rowContainer.innerHTML = "<table>" + xmlRequest.responseText + "</table>";

            const row = rowContainer.getElementsByTagName("tr")[0];
            row.prepend(this.createRemoveButton(row));

            let tBody;

            if (this.table.tBodies.length === 0) {
                tBody = document.createElement("tbody");
                this.table.appendChild(tBody);
            }
            else
                tBody = this.table.tBodies[0];

            tBody.appendChild(row);

            this.rowIndices.push(rowIndex);
            this.updateRowIndicesField();

            interactivityRegistration.attach(row);
            this.valueChanged();
        }
    }

    this.determineElements = function () {
        if (this.mode === ControlMode.edit) {
            const form = new DomQuery(this.element).getAncestor(WithTagName("FORM"));
            this.rowIndicesField = form.elements[this.name + ".RowIndices"];
        }
    };

    this.createInsertButton = function () {
        const button = document.createElement("button");
        button.classList.add("InsertRow");
        button.type = "button";
        button.addEventListener("click", (event) => { this.insertRow(); });

        const cell = document.createElement("td");
        cell.appendChild(button);

        return cell;
    };

    this.createRemoveButton = function (row) {
        const button = document.createElement("button");
        button.classList.add("RemoveRow");
        button.type = "button";
        button.addEventListener("click", (event) => { this.removeRow(row); });

        const cell = document.createElement("td");
        cell.appendChild(button);

        return cell;
    };

    this.createElements = function () {
        this.table = this.element.getElementsByTagName("table")[0];

        if (this.mode === ControlMode.edit) {
            this.table.rows[0].prepend(this.createInsertButton());

            for (let index = 1; index < this.table.rows.length; index++) {
                const row = this.table.rows[index];
                row.prepend(this.createRemoveButton(row));
            }
        }
    };

    this.determineRowIndices = function () {
        if (this.mode === ControlMode.edit) {
            const value = this.rowIndicesField.value;
            let rowIndexTexts;

            if (value.length > 0)
                rowIndexTexts = value.split(", ");
            else
                rowIndexTexts = new Array();

            const result = new Array(rowIndexTexts.length);

            this.maximumRowIndex = -1;

            for (let index = 0; index < rowIndexTexts.length; index++) {
                const rowIndex = parseInt(rowIndexTexts[index]);
                result[index] = rowIndex;

                if (rowIndex > this.maximumRowIndex)
                    this.maximumRowIndex = rowIndex;
            }

            return result;
        }
        else
            return null;
    };

    this.attachRowClickHandlers = function() {
        const rows = this.table.tBodies[0].rows;

        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];

            if (row.dataset.Uri !== undefined) {
                const uri = row.dataset.Uri;

                row.addEventListener("click", (event) => { openUrl(event, uri); } );
                row.addEventListener("mousemove", (event) => { setHot(row, event, true); });
                row.addEventListener("mouseout", (event) => { setHot(row, event, false); });
            }
        }
    }

    this.maximumRowIndex = -1;
    this.name = this.element.dataset.Name;
    this.URI = this.element.dataset.Uri;
    this.determineElements();
    this.rowIndices = this.determineRowIndices();
    this.createElements();
    this.attachRowClickHandlers();
}

interactivityRegistration.register("RecordSet", function (element) { return new RecordSetField(element); });

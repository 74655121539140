function ImageField(element) {
  WebPageComponent.call(this, element);

  this.attachHandlers = function () {
    this.image.addEventListener(
      'click', 
      (event) => {
        this.openPreview();
        event.stopPropagation();
      }
    )
  }

  this.determineElements = function () {
    this.image = new DomQuery(this.element).getChild(WithTagName("IMG"));
    this.preview = new DomQuery(this.element).getChild(WithClass("Preview"));
  }

  this.focus = function () {
    this.image.focus();
  }

  this.getName = function () {
    return this.name;
  }

  this.closePreview = function () {
    this.preview.classList.remove("Active");
    removeClickOutsideListener(this.clickOutsideListener);
  }

  this.openPreview = function () {
    this.preview.classList.add("Active");

    if (this.preview.childNodes.length === 0) {
      const image = document.createElement("IMG");
      image.src = this.preview.dataset.Uri;

      this.preview.appendChild(image);
    }

    this.clickOutsideListener = connectClickOutsideListener(
      null,
      (event) => {
          this.closePreview();
          event.stopPropagation();
      }
    );
  }

  this.name = this.element.dataset.Name;

  this.determineElements();
  this.attachHandlers();
}

interactivityRegistration.register("ImageField", function (element) { return new ImageField(element); });

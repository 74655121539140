function OriginDestinationForm(element) {
    WebPageComponent.call(this, element);

    this.attemptSubmit = function () {
        var elementsToCheck = this.element.getElementsByClassName("CheckedField");
        var result = true;

        for (var i = 0; i < elementsToCheck.length; i++)
            result = result && elementsToCheck[i].field.checkSubmitReady();

        if (!result) {
            var formElement = this.element;
            setTimeout(function() { formElement.submit() }, 100);
        }

        return result;
    }

    this.element.form = this;
}

interactivityRegistration.register("OriginDestinationForm", function (element) { return new OriginDestinationForm(element); });

var TableRenderScope = new Enumeration(["Toolbar", "Layout", "Header", "Rows", "Footer", "Complete", "Navigation"]);

function ColumnMapping(identifier, index, span) {
    this.identifier = identifier;
    this.index = index;
    this.span = span;
}

function XmlRequest(method, uri, message, handler) {
    this.method = method;
    this.uri = uri;
    this.message = message;
    this.handler = handler;
}

function XmlMessage() {
    this.toXml = function () {
        return "";
    }
}

function CommandMessage() {
    XmlMessage.call(this);

    this.toXml = function () {
        var result = "";

        for (var index = 0; index < this.commands.length; index++)
            result = result + this.commands[index];

        return "<Commands>" + result + "</Commands>";
    }

    this.commands = new Array();
}

function RenderMessage(scopes) {
    XmlMessage.call(this);

    this.toXml = function () {
        var command = "<Render>"

        scopes.forEach( function(value1, value2, set) {
            command = command + "<Scope Name=\"" + TableRenderScope.toText(value2) + "\"/>";
        });

        command += "</Render>";
        return command;
    }

    this.scopes = scopes;
}

function RequestQueue() {
    this.queue = function (request) {
        this.requests.push(request);

        if (this.requests.length === 1)
            this.sendNextRequest();
    }

    this.sendNextRequest = function () {
        this.send(this.requests[0]);
    }

    this.send = function (request) {
        var object = this;
        var xmlRequest = newXmlRequest();

        xmlRequest.open(request.method, request.uri, true);
        xmlRequest.setRequestHeader("Content-Type", "application/xml; charset=\"utf-8\"");
        xmlRequest.setRequestHeader("Accept", "text/html,application/xhtml+xml,application/xml");

        xmlRequest.onreadystatechange = function () {
            if (xmlRequest.readyState === 4) {
                if (xmlRequest.status != 0) {
                    object.requestFinished(request);
                    request.handler(xmlRequest);
                }
                else
                    object.requestFinished(request);
            }
        };

        xmlRequest.send(request.message.toXml());
    }

    this.requestFinished = function (request) {
        this.requests.shift();

        if (this.requests.length >= 1)
            this.sendNextRequest();
    }

    this.getLast = function () {
        return this.requests[this.requests.length - 1];
    }

    this.getSize = function () {
        return this.requests.length;
    }

    this.requests = new Array();
}

class CountChangedEvent {
    constructor(count) {
        this.count = count;
    }
}

function Table(element) {
    WebPageComponent.call(this, element);

    this.getTableElement = function () {
        return this.tableElement;
    }

    this.getForm = function() {
        return this.tableElement.parentNode;
    }

    this.determineTableElement = function () {
        var parent = this.contents;
        var childIndex = 0;

        if (this.useSiblingComponent)
            parent = new DomQuery(parent).getNthChild(WithTagName("FORM"), childIndex);

        return new DomQuery(parent).getChild(WithTagName("TABLE"));
    }

    this.columnClicked = function (columnIdentifier) {
        this.sendCommandRequest("<SortColumn Id=\"" + columnIdentifier + "\"/>");
    }

    this.updateSortColumn = function (element) {
        var columnIdentifier = element.getAttribute("ColumnIdentifier");
        var order = element.getAttribute("Order");
        var columnsRows = this.getColumnsRows();

        for (var i = 0; i < columnsRows.length; i++) {
            columnsRow = columnsRows[i];

            for (var j = 0; j < columnsRow.cells.length; j++) {
                var cell = columnsRow.cells[j];
                var id = cell.dataset.Identifier;

                var icon = new DomQuery(cell).getDescendant(WithClass("Sort"));

                if (icon !== null) {
                    var classes = new HtmlClasses(icon);

                    classes.remove("Ascending");
                    classes.remove("Descending");

                    if (id == columnIdentifier) {
                        if (order == 0)
                            classes.add("Ascending");
                        else
                            classes.add("Descending");
                    }
                }
            }
        }
    }

    this.getHeaderRowWithClass = function (className) {
        var table = this.getTableElement();
        var rows = table.tHead.rows;
        var result = null;
        var i = 0;

        while (result == null && i < rows.length) {
            if (nodeHasClass(rows[i], className))
                result = rows[i];
            else
                i++;
        }

        return result;
    }

    this.getHeaderRowsWithClass = function (className) {
        var table = this.getTableElement();
        var rows = table.tHead.rows;
        var result = new Array();
        var i = 0;

        while (i < rows.length) {
            if (nodeHasClass(rows[i], className)) {
                result.push(rows[i]);
            }

            i++;
        }

        return result;
    }

    this.getSearchRow = function () {
        return new DomQuery(this.toolbar.element).getDescendant(WithClass("Search"));
    }

    this.getSearchInput = function () {
        const row = this.getSearchRow();

        if (row !== null)
            return new DomQuery(row).getDescendant(WithTagName("INPUT"));
        else
            return null;
    }

    this.getFilterRows = function () {
        return this.getHeaderRowsWithClass("Filter");
    }

    this.getColumn = function (identifier) {
        var columnGroup = this.tableElement.firstChild;
        var column = null;

        for (var node of columnGroup.childNodes)
            if (node.dataset.Identifier === identifier)
                column = node;

        return column;
    }

    this.getColumnsRows = function () {
        return this.getHeaderRowsWithClass("Columns");
    }

    this.toggleFilter = function () {
        this.filterEnabled = !this.filterEnabled;
        this.sendCommandRequest("<Filter Visible=\"" + this.filterEnabled + "\"/>");
        this.updateFilterVisibility();
    }

    this.setView = function (view) {
        if (view !== this.element.dataset.View) {
            this.element.dataset.View = view;
            this.sendCommandRequest("<View Value=\"" + this.element.dataset.View + "\"/>");
        }
    }

    this.updateFilterVisibility = function () {
        const rows = this.getFilterRows();

        for (const row of rows)
            setNodeClassEnabled(row, "Enabled", this.filterEnabled);
    }

    this.toggleLayoutPanel = function () {
        this.layoutPanel.visible.toggle();
    }

    this.scheduleFilterOperation = function (operation) {
        this.filterTimer.kill();
        this.filterTimer.schedule(operation);
    }

    this.searchTextChanged = function (textBox) {
        if (textBox.value !== this.filterText) {
          this.filterText = textBox.value;
          this.scheduleFilterOperation(() => this.sendCommandRequest("<Search Text=\"" + escapeXMLAttributeValue(textBox.value) + "\"/>"));
        }
    }

    this.sendCommandRequest = function (command) {
        var object = this;
        var message = null;

        if (this.requestQueue.getSize() >= 2) {
            var request = this.requestQueue.getLast();

            if (request.message instanceof CommandMessage) {
                message = request.message;
                message.commands.push(command);
            }
        }

        if (message === null) {
            message = new CommandMessage();
            message.commands.push(command);

            this.updatingCounter.increase();
            this.requestQueue.queue(new XmlRequest("POST", this.uri, message, function (xmlRequest) { object.handleHttpCommandResponse(xmlRequest); }));
        }
    }

    this.handleHttpCommandResponse = function (http) {
        const contentType = http.getResponseHeader('Content-Type');

        if (contentType.slice(0, 15) == "application/xml") {
            const response = http.responseXML;
            const query = new DomQuery(response.documentElement);

            const cursorElement = query.getChild(WithTagName("Cursor"));

            if (cursorElement !== null) {
                this.navigation.update(cursorElement);
                this.fireCountChangedEvent();
            }

            const scopesElement = query.getChild(WithTagName("Scopes"));
            const scopeElements = new DomQuery(scopesElement).getChildren(WithTagName("Scope"));

            const scopes = new Array();

            for (const scope of scopeElements)
                scopes.push(TableRenderScope.fromText(scope.getAttribute("Name")));

            const sortElement = query.getChild(WithTagName("Sort"));

            if (sortElement !== null)
                this.updateSortColumn(sortElement);

            this.invalidate(scopes);
        }

        this.updatingCounter.decrease();
    }

    this.sendRenderRequest = function (scopes) {
        var object = this;
        this.updatingCounter.increase();

        this.requestQueue.queue(
            new XmlRequest(
                "POST",
                this.uri,
                new RenderMessage(scopes),
                function (xmlRequest) { object.handleHttpRenderResponse(xmlRequest, scopes); }
            )
        );
    }

    this.handleHttpRenderScopeResponse = function(element, scope) {
        if (scope === TableRenderScope.Toolbar)
            this.replaceToolbar(element.firstChild, true);
        else if (scope === TableRenderScope.Layout)
            this.replaceLayoutPanel(element.firstChild.firstChild);
        else if (scope === TableRenderScope.Header)
            this.replaceHeader(element.firstChild.tHead, element.firstChild.firstChild);
        else if (scope === TableRenderScope.Rows)
            this.replaceBody(element.firstChild.firstChild);
        else if (scope === TableRenderScope.Footer)
            this.replaceFooter(element.firstChild.tFoot);
        else if (scope === TableRenderScope.Navigation)
            this.replaceNavigation(element.firstChild)
        else if (scope === TableRenderScope.Complete) {
            var newElement = element.firstChild;
            var newToolbar = new DomQuery(newElement).getChild(WithClass("Toolbar"));
            var newLayoutPanel = new DomQuery(newElement).getChild(WithClass("Layout"));
            var newTable = new DomQuery(newElement).getChild(WithClass("Contents")).firstChild;

            this.loadSettings(newElement);

            this.replaceTable(newTable);
            this.replaceToolbar(newToolbar, false);
            this.replaceLayoutPanel(newLayoutPanel);
        }
    }

    this.handleHttpRenderResponse = function (http, scopes) {
        var table = this;
        var dummyElement = document.createElement("div");
        dummyElement.innerHTML = http.responseText;

        var container = dummyElement.firstChild;
        var query = new DomQuery(container);

        scopes.forEach(
            function (value1, value2, set) {
                var element = query.getChild(WithClass(TableRenderScope.toText(value2)));
                table.handleHttpRenderScopeResponse(element, value2);
            }
        );

        this.updatingCounter.decrease();
        this.renderStatus = true;
        this.updateFilterVisibility();

        if (this.selectAll !== null)
            this.selectAll.recalculate(this);
    }

    this.invalidate = function (scopes) {
        for (var scope of scopes)
            this.invalidScopes.add(scope);

        if (this.invalidScopes.size !== 0) {
            this.sendRenderRequest(new Set(this.invalidScopes));
            this.invalidScopes.clear();
        }
    }

    this.replaceHeader = function(header, columnGroup) {
        interactivityRegistration.detach(this.tableElement.tHead);
        this.tableElement.replaceChild(header, this.tableElement.tHead);
        this.tableElement.replaceChild(columnGroup, this.tableElement.childNodes[0])
        interactivityRegistration.attach(header);

        this.createSelector();

        this.attachTableEventHandlers();
        this.fireChangedEvent(this.tableElement);
    }

    this.replaceFooter = function(footer) {
        if (footer !== null)
            this.tableElement.replaceChild(footer, this.tableElement.tFoot);
    }

    this.replaceNavigation = function(navigation) {
        this.element.replaceChild(navigation, this.navigation.element);

        this.navigation = new Navigation(navigation);
        this.attachNavigationHandlers();
    }

    this.attachNavigationHandlers = function() {
        if (this.navigation.nextPage !== null) {
            this.navigation.nextPage.addEventListener("click", (event) => this.moveCursor("Up"));
            this.navigation.previousPage.addEventListener("click", (event)  => this.moveCursor("Down"));
        }
        else {
            this.navigation.loadRows.addEventListener("click", (event) => this.setPageSize(this.navigation.increasePageSize(this.navigation.size)));
        }
    }

    this.moveCursor = function (direction) {
        this.sendCommandRequest("<CursorMove Direction=\"" + direction + "\"/>");
    }

    this.setPageSize = function(count) {
        this.sendCommandRequest("<Cursor PageSize=\"" + count + "\"/>");
    }

    this.replaceBody = function (body) {
        interactivityRegistration.detach(this.tableElement.tBodies[0]);
        this.tableElement.replaceChild(body, this.tableElement.tBodies[0]);
        interactivityRegistration.attach(body);

        this.initializeCells();
           this.attachRowHoverEventHandlers(this.tableElement);

        if (this.rowSelectorEnabled)
            this.attachRowSelectorEventHandlers(this.tableElement);

        this.fireChangedEvent(this.tableElement);
    }

    this.replaceTable = function (table) {
        interactivityRegistration.detach(this.contents.firstChild);
        this.contents.replaceChild(table, this.contents.firstChild);
        this.tableElement = this.determineTableElement();

        interactivityRegistration.attach(table);

        this.initializeCells();
        this.attachRowHoverEventHandlers(this.tableElement);

        if (this.rowSelectorEnabled)
            this.attachRowSelectorEventHandlers(this.tableElement);

        this.createSelector();
        this.attachTableEventHandlers();
        this.fireChangedEvent(this.tableElement);
    }

    this.replaceToolbar = function (toolbar, persistForm) {
        this.toolbar.replace(toolbar, persistForm);

        this.attachMenuEventHandlers();
        this.attachViewHandlers();
    }

    this.replaceLayoutPanel = function (layoutPanel) {
        interactivityRegistration.detach(this.layoutPanel.element);
        this.element.replaceChild(layoutPanel, this.layoutPanel.element);
        interactivityRegistration.attach(layoutPanel);
        this.layoutPanel = new LayoutPanel(layoutPanel, this);
    }

    this.fireChangedEvent = function (newTable) {
        if (this.onChanged !== null)
            this.onChanged(newTable);
    }

    this.fireCountChangedEvent = function () {
        distributeEventUpHierarchy(new CountChangedEvent(this.navigation.total), this);
    }

    this.selectRow = function (checkBox) {
        var tableRow = new DomQuery(checkBox).getAncestor(WithTagName("TR"));
        setNodeClassEnabled(tableRow, "selectedRow", checkBox.checked);
    }

    this.selectAllRows = function (checked) {
        var table = this.getTableElement();

        for (var i = 0; i < table.tBodies.length; i++) {
            var rows = table.tBodies[i].rows;

            for (var j = 0; j < rows.length; j++) {
                var cell = rows[j].cells[0];
                var checkBoxToChange = cell.getElementsByTagName("input")[0];

                if (checkBoxToChange != null && checkBoxToChange.checked !== checked && !checkBoxToChange.disabled) {
                    checkBoxToChange.checked = checked;
                    this.selectRow(checkBoxToChange);
                }
            }
        }

        this.fireSelectionChangedEvent();
    }

    this.createRowSelectionEventHandler = function (checkBox) {
        var table = this;

        if (checkBox.checked)
            table.selectRow(checkBox);

        return function () {
            table.selectRow(checkBox);
            table.fireSelectionChangedEvent();
        };
    }

    this.createRowHotHandler = function (row, flag) {
        return function (event) {
            setHot(row, event, flag);
        };
    }

    this.createRowClickedHandler = function (row, uri) {
        var table = this;

        return function (event) {
            var source = getEvent(event).getSource();

            if (shouldHandleMouseClick(row, source))
                openUrl(event, uri);
        };
    }

    this.attachRowHoverEventHandlers = function (table) {
        for (var i = 0; i < table.tBodies.length; i++) {
            var rows = table.tBodies[i].rows;

            for (var j = 0; j < rows.length; j++) {
                var row = rows[j];
                var uri = row.dataset.Uri;

                if (uri !== undefined) {
                    row.onmouseover = this.createRowHotHandler(row, true);
                    row.onmouseout = this.createRowHotHandler(row, false);
                    row.onclick = this.createRowClickedHandler(row, uri);
                }
            }
        }
    }

    this.attachRowSelectorEventHandlers = function (table) {
        for (var i = 0; i < table.tBodies.length; i++) {
            var rows = table.tBodies[i].rows;

            for (var j = 0; j < rows.length; j++) {
                var row = rows[j];
                var cell = row.cells[0];
                var checkBox = cell.getElementsByTagName("input")[0];

                if (checkBox !== undefined)
                    checkBox.addEventListener("change", this.createRowSelectionEventHandler(checkBox));
            }
        }
    }

    this.refreshScopes = function (scopes) {
        var command = "<Refresh>"

        for (var scope of scopes)
            command = command + "<Scope Name=\"" + TableRenderScope.toText(scope) + "\"/>";

        command += "</Refresh>";

        this.sendCommandRequest(command);
    }

    this.refresh = function () {
        this.refreshScopes([TableRenderScope.Toolbar, TableRenderScope.Header, TableRenderScope.Rows, TableRenderScope.Footer, TableRenderScope.Navigation]);
    }

    this.refreshFromResponse = function (http) {
        this.handleHttpRenderResponse(http, new Set(TableRenderScope.Complete));
    }

    this.createColumnClickedHandler = function (object, columnIdentifier) {
        return function (event) { object.columnClicked(columnIdentifier) };
    }

    this.attachColumnSortHandlers = function () {
        var object = this;
        var columnsRows = this.getColumnsRows();
        var totalSpan = 0;

        for (var i = 0; i < columnsRows.length; i++) {
            columnsRow = columnsRows[i];

            for (var j = 0; j < columnsRow.cells.length; j++) {
                var cell = columnsRow.cells[j];
                var classes = new HtmlClasses(cell);

                if (classes.contains("Column")) {
                    var columnIdentifier = cell.dataset.Identifier;
                    classes.add("Action");
                    cell.addEventListener("click", this.createColumnClickedHandler(object, columnIdentifier));
                    this.columnIdentifierIndexMapping[columnIdentifier] = new ColumnMapping(columnIdentifier, totalSpan, cell.colSpan);
                    totalSpan += cell.colSpan;
                }
            }
        }
    }

    this.resizeColumn = function (columnIdentifier, delta) {
       var column = this.getColumn(columnIdentifier);

       var reference = document.createElement("span");
       reference.innerHTML = '0';
       reference.style.visibility = "hidden";
       reference.style.fontSize = window.getComputedStyle(this.tableElement.tBodies[0]).fontSize;
       reference.style.fontWeight = 'bold';
       reference.style.display = 'block'
       reference.style.width = 'min-content'

       document.body.appendChild(reference);
       var ratio = parseFloat(window.getComputedStyle(reference).width);
       document.body.removeChild(reference);

       var width = parseFloat(column.style.width) + delta / ratio;
       width = Math.max(width, 6);

       column.style.width = width + "ch";
    }

    this.commitColumnSize = function (columnIdentifier) {
        var command;
        var width;

        var column = this.getColumn(columnIdentifier);
        width = parseFloat(column.style.width);

        command = "<Resize Id=\"" + columnIdentifier + "\" Width=\"" + width + "\"/>";
        this.sendCommandRequest(command);
    }

    this.startResize = function() {
        this.tableElement.style.userSelect = 'none';
    }

    this.finishResize = function() {
        this.tableElement.style.userSelect = '';
    }

    this.attachColumnResizeHandler = function(object, cell) {
        var pageX, column;

        cell.addEventListener("mousedown", function(event) {
            pageX = event.pageX;
            column = event.target;

            object.startResize();
            cell.classList.add("Dragging");
        });

        document.addEventListener("mousemove", function(event) {
            if (column !== undefined) {
                if (pageX !== undefined)
                    object.resizeColumn(column.dataset.Identifier, event.pageX - pageX);

                pageX = event.pageX;
            }
        });

        document.addEventListener("mouseup", function(event) {
            if (column !== undefined) {
                object.commitColumnSize(column.dataset.Identifier);
                event.stopPropagation();

                cell.classList.remove("Dragging");
                object.finishResize();
            }

            pageX = undefined;
            column = undefined;
        });

        cell.onclick = function(event) {
            event.stopPropagation();
        };
    }

    this.attachColumnResizeHandlers = function () {
        var object = this;
        var columnsRows = this.getColumnsRows();
        var totalSpan = 0;

        for (var i = 0; i < columnsRows.length; i++) {
            columnsRow = columnsRows[i];

            for (var j = 0; j < columnsRow.cells.length; j++) {
                var cell = columnsRow.cells[j];
                var resizer = new DomQuery(cell).getDescendant(WithClass("Resizer"));

                if (resizer !== null)
                    this.attachColumnResizeHandler(object, resizer);
            }
        }

    }

    this.createColumnFilterHandler = function() {
        return () => {
            this.scheduleFilterOperation(
                () => {
                    let command = "";
                    let filters = 0;

                    for (const field of this.filters) {
                        const expression = field.getFilterText();
                        const mode = field.getFilterMode();

                        if (field.initialFilterText !== expression || field.initialFilterMode !== mode)
                            command = command + "<FilterColumn Id=\"" + field.getId() + "\" Mode=\"" + escapeXMLAttributeValue(mode) + "\" Text=\"" + escapeXMLAttributeValue(expression) + "\"/>";

                        field.initialFilterText = field.getFilterText();
                        field.initialFilterMode = field.getFilterMode();

                        const rows = this.getColumnsRows();

                        for (const row of rows) {
                            for (let index = 0; index < row.cells.length; index++) {
                                let cell = row.cells[index];

                                if (cell.dataset.Identifier === field.getId()) {
                                    const icon = new DomQuery(cell).getDescendant(WithClass("Filter"));

                                    if (icon !== null) {
                                        const classes = new HtmlClasses(icon);

                                        if (expression.length > 0) {
                                            classes.add("Value");
                                            filters++;
                                        }
                                        else
                                            classes.remove("Value");
                                    }
                                }
                            }
                        }
                    }

                    const toggleFilter = new DomQuery(this.element).getDescendant(WithClass("ToggleFilter"));

                    if (filters > 0)
                        toggleFilter.childNodes[0].innerText = filters;
                    else
                        toggleFilter.childNodes[0].innerText = "";

                    this.sendCommandRequest(command);
                }
            );
        }
    }

    this.attachSearchHandler = function () {
        const searchInput = this.getSearchInput();

        if (searchInput !== null) {
            this.filterText = searchInput.value;
            searchInput.addEventListener("input", (event) => this.searchTextChanged(searchInput))
        }
    }

    this.attachColumnFilterHandlers = function () {
        var object = this;
        var filterRows = this.getFilterRows();

        object.filters = new Array();

        for (var i = 0; i < filterRows.length; i++) {
            var filterRow = filterRows[i];

            for (var j = 0; j < filterRow.cells.length; j++) {
                var cell = filterRow.cells[j];
                var classes = new HtmlClasses(cell);

                var query = new DomQuery(cell);

                if (classes.contains("Column")) {
                    var element = query.getDescendant(WithClass("Box"));
                    var id = cell.dataset.Identifier;

                    if (element !== null)
                        object.filters.push(new FilterField(element, object.createColumnFilterHandler(object), id));
                }
            }
        }
    }

    this.attachMenuEventHandlers = function () {
        var object = this;

        this.attachToolbarButtonEventHandler("ToggleFilter", function (event) { object.toggleFilter(); });
        this.attachToolbarButtonEventHandler("Refresh", function (event) { object.refresh(); });
        this.attachToolbarButtonEventHandler("Layout", function (event) { object.toggleLayoutPanel(); });
    }

    this.toggleAction = function(action) {
        this.sendCommandRequest("<Action Name=\"" + action + "\"/>");
    }

    this.attachViewHandlers = function (){
        const view =  new DomQuery(this.element).getDescendant(WithClass("View"));
        const auto = new DomQuery(view).getChild(WithClass("Auto"));
        const list = new DomQuery(view).getChild(WithClass("List"));
        const columns = new DomQuery(view).getChild(WithClass("Columns"));
        const tiles = new DomQuery(view).getChild(WithClass("Tiles"));

        auto.addEventListener("click", (event) => this.setView("Auto"));
        list.addEventListener("click", (event) => this.setView("List"));
        columns.addEventListener("click", (event) => this.setView("Columns"));
        tiles.addEventListener("click", (event) => this.setView("Tiles"));
    }

    this.attachToolbarButtonEventHandler = function (action, handler) {
        var query = new DomQuery(this.element);
        var item = query.getDescendant(WithClass(action));

        if (item !== null)
            item.addEventListener("click", handler);
    }

    this.attachTableEventHandlers = function () {
        var object = this;

        this.attachColumnSortHandlers();
        this.attachColumnFilterHandlers();
        this.attachColumnResizeHandlers();

        if (this.selectAll !== null)
            this.selectAll.checkBox.addEventListener("change", function (event) { object.selectAllRows(object.selectAll.checkBox.getValue()); });

        this.onSelectionChanged = function () {
            var request = "<Selection>"
            var selection = object.getSelectedRows();

            for (var i = 0; i < selection.length; i++)
                request = request + "<Row>" + escapeXMLCharacterData(selection[i]) + "</Row>";

            request = request + "</Selection>";
            object.sendCommandRequest(request);
        }
    }

    this.handleInitialRenderTime = function () {
        if (this.renderTime === "Immediate") {
            this.renderStatus = true;
            this.fireCountChangedEvent();
        }
        else if (this.renderTime === "Deferred")
            this.refresh();
    }

    this.assureRendered = function () {
        if (!this.renderStatus)
            this.refresh();
    }

    this.getSelectedRows = function() {
        var table = this.getTableElement();
        var rows = table.tBodies[0].rows;
        var result = new Array();
        var index = 0;

        while (index < rows.length) {
            if (nodeHasClass(rows[index], "selectedRow"))
                result.push(rows[index].dataset.Identifier);

            index++;
        }

        return result;
    }

    this.getSelectedRowCount = function() {
        var table = this.getTableElement();
        var rows = table.tBodies[0].rows;
        var index = 0;
        var count = 0;

        while (index < rows.length) {
            if (nodeHasClass(rows[index], "selectedRow"))
                count++;

            index++;
        }

        return count;
    }

    this.getRowCount = function() {
        var table = this.getTableElement();
        var rows = table.tBodies[0].rows;
        var index = 0;
        var count = 0;

        while (index < rows.length) {
            var cell = rows[index].cells[0];
            var checkBox = cell.getElementsByTagName("input")[0];

            if (checkBox != null && !checkBox.disabled)
                count++;

            index++;
        }

        return count;
    }

    this.createSelector = function() {
        this.selectAll = null;

        if (this.rowSelectorEnabled) {
            var element =
                new DomPathQuery(this.tableElement)
                .getChild(WithTagName("THEAD"))
                .getChild(WithClass("Columns"))
                .getChild(WithClass("rowSelector"))
                .getChild(function (node) { return node.component !== undefined && node.component instanceof CheckBoxField })
                .getElement();

            if (element !== null) {
                this.selectAll = new Selector(element);
                this.selectAll.recalculate(this);
            }
        }
    }

    this.fireDataChanged = function () {
        distributeEvent(new DataChangedEvent(this));
    }

    this.handleEvent = function (event) {
        if (event instanceof DataChangedEvent && this.refreshMode == RefreshMode.ForSiblings)
            this.refreshScopes([TableRenderScope.Complete]);
    }

    this.loadSettings = function(element) {
        this.filterEnabled = element.dataset.FilterEnabled === "true";
        this.rowSelectorEnabled = element.dataset.RowSelectorEnabled === "true";
        this.useSiblingComponent = element.dataset.UseSiblingComponent === "true";
        this.renderTime = element.dataset.RenderTime;
        this.uri = element.dataset.Uri;
        this.view = element.dataset.View;

        this.element.tabIndex = "0";
        this.renderStatus = false;
    }

    this.initializeComponents = function() {
        this.requestQueue = new RequestQueue();
        this.updatingCounter = new HtmlClassCounter(this.element, "Updating");
        this.invalidScopes = new Set();
        this.columnIdentifierIndexMapping = new Object();
        this.onChanged = null;

        this.filterTimer = new Timer(400);
        this.filterText = "";
        this.updateFilterVisibility();

        this.attachRowHoverEventHandlers(this.tableElement);

        if (this.rowSelectorEnabled)
            this.attachRowSelectorEventHandlers(this.tableElement);

        this.createSelector();
    }

    this.initializeCells = function() {
        const header = this.getColumnsRows()[0];

        let columnIndex = 0;

        for (const column of header.cells) {
            const caption = new DomQuery(column).getDescendant(WithClass("Cell"));

            for (const row of this.tableElement.tBodies[0].rows) {
                if (caption !== null) {
                    const node = caption.cloneNode(true);

                    row.cells[columnIndex].prepend(node);
                }
            }

            columnIndex += column.colSpan;
        }

        for (const row of this.tableElement.tBodies[0].rows) {
            const subs = new DomQuery(row).getChildren(WithClass("Sub"));

            for (const sub of subs) {
                const field = sub.childNodes[1];

                if ('Value' in field.dataset) {
                    if (field.dataset.Value.length === 0)
                        sub.classList.add("Empty");
                }
                else if (sub.childNodes[1].innerText.length === 0)
                    sub.classList.add("Empty");
            }

            const sub = subs.findLast((element) => !element.classList.contains("Empty"));

            if (sub !== undefined)
                sub.classList.add("Last");
        }
    }

    this.determineElements = function() {
        this.contents = new DomQuery(this.element).getChild(WithClass("Contents"));
        this.tableElement = this.determineTableElement();

        this.toolbar = new Toolbar(new DomQuery(this.element).getChild(WithClass("Toolbar")));
        this.toolbar.parentComponent = this;

        this.navigation = new Navigation(new DomQuery(this.element).getChild(WithClass("NavigationBar")));
        this.layoutPanel = new LayoutPanel(new DomQuery(this.element).getChild(WithClass("Layout")), this);
    }

    this.bind = function() {
        this.handleInitialRenderTime();
    }

    this.loadSettings(this.element);
    this.determineElements();
    this.initializeComponents();
    this.initializeCells();

    this.attachNavigationHandlers();
    this.attachSearchHandler();
    this.attachMenuEventHandlers();
    this.attachViewHandlers();
    this.attachTableEventHandlers();
}

function LayoutPanel(element, table) {
    WebPageComponent.call(this, element);

    this.createColumnClickHandler = function (column) {
        var object = this;

        return function (event) {
            object.toggleColumn(column);
        };
    }

    this.createColumnDragStartHandler = function (column) {
        var object = this;

        return function (event) {
            object.sourceColumn = column;
            object.sourceIndex = object.columns.indexOf(column);

            column.classList.add("Moving");
            event.dataTransfer.effectAllowed = "move";
            event.dataTransfer.dropEffect = "move";
            event.dataTransfer.setData("text/plain", "move-column");
        };
    }

    this.createColumnDragEndHandler = function (column) {
        var object = this;

        return function (event) {
            if (!object.dropped) {
                if (object.columns.indexOf(column) < object.sourceIndex)
                    object.moveColumn(object.sourceIndex + 1);
                else
                    object.moveColumn(object.sourceIndex);
            }

            object.sourceColumn.classList.remove("Moving");
            object.sourceColumn = null;
            object.dropped = false;
        };
    }

    this.createOnDragOverHandler = function () {
        var object = this;

        return function (event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = "move";

            object.moveColumn(object.determineTargetIndex(event.target.offsetLeft + event.offsetX));
        };
    }

    this.createOnDragLeaveHandler = function (column) {
        var object = this;

        return function (event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = "move";
        };
    }

    this.createOnDragExitHandler = function (column) {
        var object = this;

        return function (event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = "move";
        };
    }

    this.createOnDragEnterHandler = function (column) {
        var object = this;

        return function (event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = "move";
        };
    }

    this.createOnDropHandler = function (column) {
        var object = this;

        return function (event) {
            event.preventDefault();
            object.dropColumn();
        };
    }

    this.toggleColumn = function (column) {
        if (column.dataset.Visible === "true")
            column.dataset.Visible = "false";
        else
            column.dataset.Visible = "true";

        this.updateProjection();
    }

    this.getColumnMiddle = function (column) {
        return column.offsetLeft + column.offsetWidth / 2;
    }

    this.determineTargetIndex = function (offsetX) {
        var result = 0;

        while (result < this.columns.length && this.getColumnMiddle(this.columns[result]) < offsetX)
            result++;

        return result;
    }

    this.moveColumn = function (targetIndex) {
        var sourceIndex = this.columns.indexOf(this.sourceColumn);

        if (targetIndex !== sourceIndex && targetIndex !== sourceIndex + 1) {
            var targetColumn = this.columns[targetIndex];

            this.projection.insertBefore(this.sourceColumn, targetColumn);
            this.columns = new DomQuery(this.projection).getChildren(WithClass("Column"));
        }
    }

    this.dropColumn = function () {
        this.updateProjection();
        this.dropped = true;
    }

    this.updateProjection = function () {
        this.table.sendCommandRequest("<Projection>" + this.getCurrentProjectionAsXml() + "</Projection>");
    }

    this.attachEventHandlers = function () {
        this.projection = new DomQuery(this.element).getChild(WithClass("Projection"));
        this.projection.ondragover = this.createOnDragOverHandler();
        this.projection.ondragleave = this.createOnDragLeaveHandler();
        this.projection.ondragenter = this.createOnDragEnterHandler();
        this.projection.ondragexit = this.createOnDragExitHandler();
        this.projection.ondrop = this.createOnDropHandler();

        this.columns = new DomQuery(this.projection).getChildren(WithClass("Column"));

        for (var index = 0; index < this.columns.length; index++) {
            var column = this.columns[index];
            column.onclick = this.createColumnClickHandler(column);
            column.ondragstart = this.createColumnDragStartHandler(column);
            column.ondragend = this.createColumnDragEndHandler(column);
            column.draggable = true;
        }
    }

    this.handleLayoutDropDownChanged = function () {
        this.table.sendCommandRequest("<SelectLayout Name=\"" + escapeXMLAttributeValue(this.layoutDropDown.getValue()) + "\"/>");
    }

    this.handleShareButtonClicked = function () {
        var object = this;

        object.confirmationWindow.show(
            function () {
                var layoutName = "Standard";
                object.table.sendCommandRequest("<ShareLayout Name=\"" + escapeXMLAttributeValue(layoutName) + "\"/>");
            }
        );
    }

    this.createOnLayoutDropDownChangedHandler = function () {
        var object = this;

        return function (event) {
            object.handleLayoutDropDownChanged();
        }
    }

    this.createOnShareButtonClickHandler = function () {
        var object = this;

        return function (event) {
            object.handleShareButtonClicked();
        }
    }

    this.bind = function () {
        this.predefined = new DomQuery(this.element).getChild(WithClass("Predefined"));

        this.layoutDropDown = this.getDescendant(new ComponentWithName("Layout"));
        this.layoutDropDown.addEventListener("change", this.createOnLayoutDropDownChangedHandler());

        this.shareButton = new DomQuery(this.predefined).getChild(WithClass("Share"));

        if (this.shareButton !== null) {
            this.shareButton.onclick = this.createOnShareButtonClickHandler();
            this.confirmationWindow = new ConfirmationWindow(new DomQuery(this.predefined).getChild(WithClass("Confirmation")));
        }
    }

    this.getCurrentProjectionAsXml = function () {
        var result = "";

        for (var index = 0; index < this.columns.length; index++) {
            var column = this.columns[index];
            result += "<Column Index=\"" + column.dataset.Index + "\" Visible=\"" + column.dataset.Visible + "\"/>";
        }

        return result;
    }

    this.table = table;
    this.visible = new HtmlClassSwitch(element, "Visible");
    this.sourceColumn = null;
    this.dropped = false;
    this.projection = null;
    this.predefined = null;
    this.layoutDropDown = null;
    this.shareButton = null;
    this.confirmationWindow = null;

    this.attachEventHandlers();
    this.bind();
}

function Navigation(element) {
    this.determineElements = function() {
        const query = new DomQuery(this.element);

        this.information = query.getChild(WithClass("Information"));
        this.loadRows = query.getChild(WithClass("LoadRows"));
        this.nextPage = query.getChild(WithClass("NextPage"));
        this.previousPage = query.getChild(WithClass("PreviousPage"));

        this.determineButtonStates();
    }

    this.determineButtonStates = function() {
        if (this.loadRows !== null)
            this.loadRows.disabled = this.size >= this.total || this.size === 1000;
        else {
            this.nextPage.disabled = this.offset + this.size >= this.total;
            this.previousPage.disabled = this.offset === 0;
        }
    }

    this.increasePageSize = function(pageSize) {
        if (pageSize === 10)
            return 250;
        else if (pageSize < 1000)
            return pageSize + 250;
        else
            return pageSize;
    }

    this.update = function(xml) {
        this.information.innerText = xml.getAttribute("Label");
        this.offset = xml.getAttribute("Offset");
        this.size = xml.getAttribute("PageSize");
        this.total = xml.getAttribute("Size");

        this.determineButtonStates();
    }

    Object.defineProperty(this, 'offset', {
        get: function() {
            return parseInt(this.information.getAttribute("data--offset"));
        },
        set: function(offset) {
            this.information.setAttribute("data--offset", offset);
        }
    });

    Object.defineProperty(this, 'size', {
        get: function() {
            return parseInt(this.information.getAttribute("data--page-size"));
        },
        set: function(size) {
            this.information.setAttribute("data--page-size", size);
        }
    });

    Object.defineProperty(this, 'total', {
        get: function() {
            return parseInt(this.information.getAttribute("data--total"));
        },
        set: function(size) {
            this.information.setAttribute("data--total", size);
        }
    });

    this.element = element;
    this.determineElements();
}

function Selector(element) {
    WebPageComponent.call(this, element);

    this.checkBox = element.component;

    this.recalculate = function(table) {
        var rowCount = table.getRowCount();
        var selected = table.getSelectedRowCount();

        this.checkBox.input.indeterminate = false;

        if (rowCount == 0 || selected == 0)
            this.checkBox.input.checked = false;
        else if (selected < rowCount)
            this.checkBox.input.indeterminate = true;
        else
            this.checkBox.input.checked = true;
    }
}

function GroupingTable(element) {
    WebPageComponent.call(this, element);

    this.expandOrCollapse = function (element) {
        var classes = new HtmlClasses(element);

        classes.toggle("collapsed");
        classes.toggle("expanded");
    }

    this.createOnClickHandler = function (item) {
        var object = this;

        return function () {
            var body = new DomQuery(item).getAncestor(WithClass("expandable"));

            object.expandOrCollapse(body);
        };
    }

    this.attachItemHandlers = function (element) {
        element.onclick = this.createOnClickHandler(element);
    }

    this.attachItemsHandlers = function (elements) {
        for (var i = 0; i < elements.length; i++)
            this.attachItemHandlers(new DomQuery(elements[i]).getChild(WithClass("category")));
    }

    this.determineElements = function () {
        this.attachItemsHandlers(new DomQuery(this.element).getDescendants(WithClass("expandable")));
    }

    this.element = element;
    this.determineElements();
}

function MasterTableRow(element) {
    WebPageComponent.call(this, element);

    this.expandOrCollapse = function (element, event) {
        var source = event.getSource();

        if (shouldHandleMouseClick(element, source)) {
            var classes = new HtmlClasses(this.detailsElement);
            classes.toggle("Collapsed");
            classes.toggle("Expanded");
        }
    }

    this.createOnClickHandler = function (item) {
        var object = this;

        return function (event) {
            object.expandOrCollapse(item, getEvent(event));
        };
    }

    this.attachItemHandler = function (element) {
        element.onclick = this.createOnClickHandler(element);
    }

    this.determineElements = function () {
        this.attachItemHandler(this.element);
    }

    this.element = element;
    this.detailsElement = element.nextSibling;
    this.determineElements();
}

function FilterField(element, handler, id) {
    WebPageComponent.call(this, element);

    this.attachEventHandlers = function () {
        var component = this;

        this.element.onkeydown = function (event) {
        };

        this.target.addEventListener("input", function (event) { component.handler(); });
        this.button.onclick = function (event) { component.toggleForm(event); };

        for (var field of this.fields)
            field.onchange = function (event) { component.handler(); };
    }

    this.determineElements = function () {
        var component = this;
        component.fields = new DomQuery(this.form).getDescendants(WithTagName("INPUT"));
    }

    this.getId = function() {
        return this.id;
    }

    this.getFilterText = function () {
        return this.target.getValue();
    }

    this.getFilterMode = function () {
        for (var field of this.fields) {
            if (field.checked)
                this.filterMode = field.getAttribute("value");
        }

        return this.filterMode;
    }

    this.toggleForm = function(event) {
        var object = this;

        this.formState.toggle();

        if (!this.form.classList.contains("Collapsed")) {
            this.clickOutsideListener = connectClickOutsideListener(this.form, function (event_) {object.toggleForm(event_);});
            this.fields[0].focus();
        }
        else if (this.clickOutsideListener != null)
            removeClickOutsideListener(this.clickOutsideListener);

        event.stopPropagation();
    }

    this.handler = handler;
    this.id = id;

    this.button = element.childNodes[0];
    this.button.tabIndex = -1;

    this.target = element.childNodes[1].component;
    this.form = element.childNodes[2];

    this.fields = null;

    this.filterMode = null;
    this.formState = new HtmlClassSwitch(this.form, "Collapsed");
    this.clickOutsideHandler = null;

    this.determineElements();
    this.attachEventHandlers();

    this.initialFilterText = this.getFilterText();
    this.initialFilterMode = this.getFilterMode();
}

interactivityRegistration.register("Table", function (element) { return new Table(element); });
interactivityRegistration.register("grouping", function (element) { return new GroupingTable(element); });
interactivityRegistration.register("Master", function (element) { return new MasterTableRow(element); });

function WebSiteObjectFilters(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        for (const field of this.defaultFields)
            field.addEventListener("change", (event) => { this.filter(); });

        if (this.button != null)
            this.button.onclick = (event) => this.toggleAddForm();

        for (var value of this.values) {
            var close = new DomQuery(value).getChild(WithClass("Close"));
            close.onclick = this.createClearFilterHandler(value);
        }
    }

    this.createClearFilterHandler = function(value) {
        var object = this;
        var name = value.childNodes[1].dataset.Name;

        return function (event) {
            object.toolbar.removeChild(value);

            for (var field of object.fields) {
                if (field.getName().indexOf(name, field.getName().length - name.length) !== -1) {
                    field.setValue("");
                    object.filter();
                }
            }
        };
    }

    this.clearFilters = function() {
        this.reload(this.baseUri);
    }
    
    this.determineElements = function() {
        let query = new DomQuery(this.element);

        this.header = query.getChild(WithClass("Header"));
        this.toolbar = query.getChild(WithClass("Toolbar"));
        this.progress = new Progress(query.getChild(WithClass("Progress")));
        this.content = this.element.childNodes[3];
        
        this.clear = new DomQuery(this.header).getChild(WithClass("ClearFilters"));
        
        if (this.clear !== null) {
            this.clear.addEventListener(
                'click',
                () => {
                    this.clearFilters();
                }
            )
        }

        query = new DomQuery(this.toolbar);
        this.add = query.getChild(WithClass("Add"));
        this.defaultFilters = query.getDescendants(WithClass("DefaultFilter"));
        this.values = query.getDescendants(WithClass("FilterValue"));

        if (this.add !== null) {
            this.button = new DomQuery(this.add).getChild(WithClass("Button"));
            this.addExpanded = new HtmlClassSwitch(this.add, "Expanded");

            this.filterForm = new DomQuery(this.add).getChild(WithTagName("FORM"));
            this.filterForm.childNodes[1].childNodes[0].addEventListener(
                'click',
                () => { 
                    this.toggleAddForm(); 
                    this.filter(); 
                }
            );

            const fields = new DomQuery(this.filterForm.childNodes[0]).getDescendants(WithClass("Field"));
            this.registerFilters(fields, false);
        }

        for (const defaultFilter of this.defaultFilters)
            this.registerFilters(new DomQuery(defaultFilter).getDescendants(WithClass("Field")), true);
    }

    this.filter = function() {
        this.reload(this.getUri());
    }

    this.reload = function(uri) {
        const client = new HttpClient();
        
        client.get(
            uri + "/Filters",
            (response) => {
                const dummy = document.createElement("div");
                dummy.innerHTML = response.responseText;

                const newFilter = dummy.childNodes[0];

                this.element.parentNode.replaceChild(newFilter, this.element);
                interactivityRegistration.attach(newFilter);
                window.history.pushState(null, "", uri);
            },
            this.progress
        );
    }

    this.getQuery = function() {
        var query = "";

        for (var field of this.fields) {
            var name = field.getName();
            var value = field.getValue();

            if (value !== null && value.length > 0)
                query = query + encodeURIComponent(name) + "=" + encodeURIComponent(value) + ";";
        }

        if (query.length > 0)
            query = query.substring(0, query.length - 1);

        return query;
    }

    this.getUri = function() {
        let uri = this.baseUri;
        const query = this.getQuery();
        
        if (query.length > 0)
            uri = uri + ";" + query;

        return uri;
    }

    this.registerFilters = function(fields, defaultFilter) {
        for (var field of fields) {
            this.fields.push(field.component);

            if (defaultFilter)
                this.defaultFields.push(field.component);
        }
    }

    this.toggleAddForm = function() {
        var object = this;
        this.addExpanded.toggle();

        var listener = connectClickOutsideListener(
            object.add,
            function(event){
                object.addExpanded.toggle();
                removeClickOutsideListener(listener);
            }
        );
    }

    this.fields = new Array();
    this.defaultFields = new Array();
    this.uri = this.element.dataset.Uri;

    // TODO: provide base uri server side.
    this.baseUri = this.uri.split(';', 1);
        
    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("Filtering", function (element) { return new WebSiteObjectFilters(element); });

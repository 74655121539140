function GridPanel(element) {
    WebPageComponent.call(this, element);

    this.initialize = function() {
        this.bounds = new Rectangle(
            element.dataset.Left,
            element.dataset.Top,
            element.dataset.Right,
            element.dataset.Bottom
        );

        var rowTracks = JSON.parse(element.dataset.GridTrackRows);
        var columnTracks = JSON.parse(element.dataset.GridTrackColumns);

        if (isInternetExplorer()) {
            this.element.style.msGridRows = rowTracks.join(" ");
            this.element.style.msGridColumns = columnTracks.join(" ");
        }
        else {
            this.element.style.gridTemplateRows = rowTracks.join(" ");
            this.element.style.gridTemplateColumns = columnTracks.join(" ");
        }

        this.components = new DomQuery(this.element).getChildren(WithClass("GridItem"));

        for (var index = 0; index < this.components.length; index++) {
            var component = this.components[index];

            component.bounds = new Rectangle(
                parseInt(component.dataset.Left) + 1,
                parseInt(component.dataset.Top) + 1,
                parseInt(component.dataset.Right) + 1,
                parseInt(component.dataset.Bottom) + 1
            );

            if (isInternetExplorer()) {
                component.style.msGridColumn = component.bounds.left;
                component.style.msGridRow = component.bounds.top;
                component.style.msGridColumnSpan = component.bounds.width();
                component.style.msGridRowSpan = component.bounds.height();
            }
            else {
                component.style.gridColumn = component.bounds.left;
                component.style.gridColumnEnd = component.bounds.right;
                component.style.gridRow = component.bounds.top;
                component.style.gridRowEnd = component.bounds.bottom;
            }
        }

        for (var index = 0; index < this.components.length; index++) {
            var component = this.components[index];

            component.style.visibility = "visible";

            if (component.childNodes.length === 2) {
                if (component.childNodes[1].component !== undefined)
                    component.childNodes[1].component.handleEvent(new VisibilityChangedEvent(this));
            }
        }
    }

    this.initialize();
}

function Rectangle(left, top, right, bottom) {
    this.left = left;
    this.top = top;
    this.right = right;
    this.bottom = bottom;

    this.height = function() {
        return (this.bottom - this.top);
    }

    this.width = function() {
        return (this.right - this.left);
    }
}

function FlexPanel(element) {
    WebPageComponent.call(this, element);

    this.determineElements = function() {
        var items = new DomQuery(this.element).getChildren(WithClass("FlexItem"));
    }

    this.determineElements();
}

interactivityRegistration.register("Grid", function (element) { return new GridPanel(element); });
interactivityRegistration.register("Flex", function (element) { return new FlexPanel(element); });
function Task(element) {
    WebPageComponent.call(this, element);

    this.determineElements = function () {
        var query = new DomQuery(this.element);

        this.parent = query.getDescendant(WithClass("Parent"));
        this.count = query.getDescendant(WithClass("Count"));
        this.children = query.getDescendant(WithClass("Children"));

        if (this.children !== null) {
            this.expanded = new HtmlClassSwitch(this.element, "Expanded");
            this.expanded.setStatus(false);
        }
    }

    this.iconClicked = function (event) {
        this.expanded.toggle();
        getEvent(event).stopHandling();
    }

    this.attachEventHandlers = function () {
        var object = this;

        if (this.count !== null && this.children !== null)
            this.count.onclick = function (event) { object.iconClicked(getEvent(event)); };
    }

    this.determineElements();
    this.attachEventHandlers();
}

function ToggleDashboard(element) {
    this.determineElements = function() {
        var query = new DomQuery(this.element);

        this.icon = query.getChild(WithClass("Icon"));
        this.information = query.getChild(WithClass("Information"));
    }

    this.element = element;
    this.determineElements();
}

function Dashboard(element) {
    WebPageComponent.call(this, element);

    this.updatingSwitch = new HtmlClassSwitch(element, "Updating");
    this.uri = element.dataset.Uri;
    this.renderTime = element.dataset.RenderTime;
    this.toggleSwitch = new HtmlClassSwitch(element, "Collapsed");

    this.determineElements = function() {
        var query = new DomQuery(this.element);

        this.contents = query.getChild(WithClass("Contents"));
        this.widgets = new DomQuery(this.contents).getChildren(WithClass("widget"));

        query = new DomQuery(document);
        var element = query.getDescendant(WithClass("ToggleDashboard"));

        if (element !== null) {
            this.toggleDashboard = new ToggleDashboard(element);

            if (this.element.dataset.Count > 0)
                this.toggleDashboard.information.innerHTML = this.element.dataset.Count;
        }
        else
            this.toggleDashboard = null;

    }

    this.handleEvent = function (event) {
        if (event instanceof DataChangedEvent)
            this.refresh();
    }

    this.initialize = function (event) {
        if (this.renderTime === "Deferred")
            this.refresh();
    }

    this.toggle = function () {
        this.updatingSwitch.setStatus(true);
        this.toggleSwitch.toggle()

        var xmlRequest = newXmlRequest();
        var state = { Collapsed: this.toggleSwitch.getStatus() };
        var field = this;

        setTimeout(
            function() {
                distributeEvent(new AvailableSizeChangedEvent());
            },
            500
        );

        xmlRequest.open("POST", this.uri, true);
        xmlRequest.setRequestHeader("Content-Type", "application/json");
        xmlRequest.onreadystatechange = function () { field.updatingSwitch.setStatus(false); };
        xmlRequest.send(JSON.stringify(state));
    }

    this.refresh = function () {
        this.updatingSwitch.setStatus(true);

        var xmlRequest = newXmlRequest();
        var commands = "<Render/>";
        var field = this;

        xmlRequest.open("GET", this.uri, true);
        xmlRequest.setRequestHeader("Content-Type", "application/xml");
        xmlRequest.setRequestHeader("Accept", "text/html");
        xmlRequest.onreadystatechange = function () { field.handleHttpCommandResponse(xmlRequest); };
        xmlRequest.send(commands);
    }

    this.handleHttpCommandResponse = function (xmlRequest) {
        if (xmlRequest.readyState == 4) {
            var dummyElement = document.createElement("div");
            dummyElement.innerHTML = xmlRequest.responseText;
            var newElement = dummyElement.firstChild;

            interactivityRegistration.detach(this.element);
            this.element.parentNode.replaceChild(newElement, this.element);
            interactivityRegistration.attach(newElement);
            this.updatingSwitch.setStatus(false);
        }
    }

    this.attachToolbarButtonEventHandler = function (action, handler) {
        var query = new DomQuery(this.element);
        var item = query.getDescendant(WithClass(action));

        if (item !== null)
            item.onclick = handler;
    }

    this.attachToolbarEventHandlers = function () {
        var object = this;

        if (this.toggleDashboard !== null)
            this.toggleDashboard.icon.onclick = function (event) { object.toggle(); };

        this.attachToolbarButtonEventHandler("Close", function (event) { object.toggle(); });
        this.attachToolbarButtonEventHandler("Refresh", function (event) { object.refresh(); });
    }

    this.determineElements();
    this.attachToolbarEventHandlers();
    this.initialize();
}

interactivityRegistration.register("Task", function (element) { return new Task(element); });
interactivityRegistration.register("Dashboard", function (element) { return new Dashboard(element); });

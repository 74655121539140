function isInternetExplorer() {
    return (navigator.userAgent.toLowerCase().indexOf("msie") !== -1) || navigator.userAgent.toLowerCase().indexOf("trident") !== -1;
}

function isEdge() {
    return navigator.userAgent.toLowerCase().indexOf("edge") !== -1;
}

function isInternetExplorer6() {
    return navigator.userAgent.toLowerCase().indexOf("msie 6") !== -1;
}

function isInternetExplorer7() {
    return navigator.userAgent.toLowerCase().indexOf("msie 7") !== -1;
}

function isInternetExplorer8() {
    return navigator.userAgent.toLowerCase().indexOf("msie 8") !== -1;
}

function isFireFox() {
    return navigator.userAgent.toLowerCase().indexOf("firefox") !== -1;
}

function isChrome() {
    return navigator.userAgent.toLowerCase().indexOf("chrome") !== -1;
}

function isMobile() {
    return navigator.userAgent.toLowerCase().indexOf("mobi") !== -1;
}

function isSmallScreen() {
    return window.matchMedia('(max-width: 50em)').matches;
}

function Diagram (element) {
    WebPageComponent.call(this, element);

    this.createRefreshHandler = function() {
        var object = this;

        return function (xmlResponse) {
            var dummyElement = document.createElement("div");
            dummyElement.innerHTML = xmlResponse.responseText;
            dummyElement = dummyElement.firstChild;

            object.element.parentElement.replaceChild(dummyElement, object.element);
            object.element = dummyElement;
            object.element.component = this;
        }
    }

    this.refresh = function() {
        this.client.sendJsonRequest(this.element.dataset.Uri, {"Command": "Refresh"}, this.createRefreshHandler());
    }

    this.handleEvent = function (event) {
        if (event instanceof DataChangedEvent && this.refreshMode == RefreshMode.ForSiblings)
            this.refresh();
    }
}

function LegendItem (element) {
    WebPageComponent.call(this, element);

    this.determineElements = function () {
        var query = new DomQuery(this.element);

        this.icon  = query.getDescendant(WithTagName("svg"));
        this.table = query.getDescendant(WithTagName("TABLE"));
    }

    this.attachEventHandlers = function () {
        var object = this;

        if (this.table != null) {
            var collapsed = new HtmlClassSwitch(this.table, "Expanded");
            this.icon.onclick = function (event) { collapsed.toggle(); };
        }
    }

    this.determineElements();
    this.attachEventHandlers();
}

interactivityRegistration.register("Diagram", function (element) { return new Diagram(element); });
interactivityRegistration.register("LegendItem", function (element) { return new LegendItem(element); });
function UploadForm (element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        var form  = this;
        
        for (var i = 0; i < this.fields.length; i++) {
            var field = this.fields[i];

            if (field.name.slice(0, 7) == "Upload-") {
                if (field.type == "file") {
                    form.fileField = field;
                    field.onchange = function(event) { form.fileChanged() };
                }
                else
                    field.onchange = function(event) { form.inputChanged() };
            }
        }
    }
    
    this.determineElements = function() {
        var query = new DomQuery(this.element);

        this.fields = query.getDescendants(WithTagName("INPUT"));
    }
    
    this.inputChanged = function(event) {
        var form       = this;
        var xmlRequest = new newXmlRequest();
        
        var formData = new FormData(form.element);
        
        xmlRequest.open("POST", this.uri, true); 
        xmlRequest.onreadystatechange = function () { form.render(xmlRequest); };
        xmlRequest.send(formData);
    }
    
    this.fileChanged = function(event) {
        var uploadArea = this.element.parentNode;
        var files      = this.fileField.files;
        
        uploadArea.component.processFiles(files);
    }
        
    this.refresh = function () {
        var form       = this;
        var xmlRequest = new newXmlRequest();
        
        xmlRequest.open("GET", this.uri, true); 
        xmlRequest.onreadystatechange = function () { form.render(xmlRequest); };
        xmlRequest.send();
    }
    
    this.render = function (xmlRequest) {
        if (xmlRequest.readyState == 4) {
            var newFormDivision = document.createElement("div");
            newFormDivision.innerHTML = xmlRequest.responseText;

            interactivityRegistration.detach(this.element);
            var newForm = newFormDivision.firstChild;

            this.element.parentElement.replaceChild(newForm, this.element);
            this.element = newForm;

            interactivityRegistration.attach(newForm);
        }
    }
    
    this.element.component = this;
    
    this.fields    = null;
    this.fileField = null;
    
    this.uri = this.element.dataset.Uri;
    this.determineElements();
    this.attachHandlers();
}

function Form(element) {
    WebPageComponent.call(this, element);

    this.handleEvent = function (event) {
        if (event instanceof VisibilityChangedEvent) {
            var element = this.getElementWithComponent(event.field);

            if (element !== null)
                this.updateVisibility(event.field, element);
        }
    }

    this.getElementWithComponent = function (component) {
        var result = null;
        var index = 0;

        while (result === null && index < this.element.childNodes.length) {
            var node = this.element.childNodes[index];

            if (node.contains(component.element))
                result = node;
            else
                index++;
        }

        return result;
    }

    this.updateVisibility = function (field, element) {
        var visibility = new HtmlClassSwitch(element, "Hidden");
        visibility.setStatus(!field.visible);
    }

    this.createPreventDoubleSubmitHandler = function () {
        var object = this;

        return function (event) {
            if (object.alreadySubmitted) {
                event.preventDefault();
                alert("Form already submitted");

                return false;
            }
            else {
                object.alreadySubmitted = true;
                return true;
            }
        }
    }

    this.focus = function() {
        var target = this.childComponents.find(child => child.isFocusable());

        if (target !== undefined)
            target.focus();
    }

    this.alreadySubmitted = false;
    this.element.onsubmit = this.createPreventDoubleSubmitHandler();
}

interactivityRegistration.register("Form", function (element) { return new Form(element); });
interactivityRegistration.register("UploadForm", function (element) { return new UploadForm(element); });

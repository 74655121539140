function MapDataEntry() {
    this.title;
    this.latitude;
    this.longitude;
    this.color;
    this.radius;
    this.scale;
    this.borderColor;
    this.fillColor;
    this.clickable;
    this.imageUri;
    this.information;
}

function LegendEntry(element) {
    this.element = element;
    this.checkBox = new DomQuery(element).getDescendant(WithTagName("INPUT"));
}

function Legend(element, googleMapsField) {
    this.checkBoxClicked = function () {
        var layers = [];

        for (var i = 0; i < this.entries.length; i++) {
            var entry = this.entries[i];

            if (entry.checkBox.checked)
                layers.push(entry.checkBox.name);
        }

        this.googleMapsField.drawMarkersWithLayers(layers);
    }

    this.createCheckBoxClickedHandler = function (object) {
        return function () { object.checkBoxClicked() };
    }

    this.determineElements = function() {
        var object = this;
        var query = new DomQuery(element);
        var legendEntries = query.getDescendants(WithClass("LegendEntry"));

        for (var i = 0; i < legendEntries.length; i++) {
            var entry = new LegendEntry(legendEntries[i]);

            entry.checkBox.onclick = this.createCheckBoxClickedHandler(object);
            this.entries.push(entry);
        }
    }

    this.element = element;
    this.googleMapsField = googleMapsField;
    this.entries = [];
    this.determineElements();
}

function GoogleMapsField(element) {
    WebPageComponent.call(this, element);

    this.determineElements = function () {
        this.element.field = this;
        var object = this;

        var query = new DomQuery(this.element);

        this.latitudeField = query.getDescendant(WithClass("latitude"));
        this.longitudeField = query.getDescendant(WithClass("longitude"));

        if (query.getDescendant(WithClass("address")) !== null) {
            this.addressField = query.getDescendant(WithClass("address")).childNodes[0];
        }

        this.canvas = query.getDescendant(WithClass("canvas"));
        this.mapData = query.getDescendant(WithClass("mapData"));

        this.informationWindow = query.getDescendant(WithClass("Information"));

        if (this.informationWindow !== null) {
            this.showInformation = new HtmlClassSwitch(this.informationWindow, "Expanded");
            this.showInformation.setStatus(false);

            this.showInformationToggle = new HtmlClassSwitch(this.informationWindow, "Toggle");
        }

        this.loadMapData();

        var legendElement = query.getDescendant(WithClass("Legend"));

        if (legendElement !== null)
            this.legend = new Legend(legendElement, object);
    }

    this.loadMapData = function () {
        var table = this.mapData.childNodes[0];
        var rows = table.tBodies[0].rows;
        var mapDataEntries = [];

        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            var mapDataEntry = new MapDataEntry();

            mapDataEntry.title = row.cells[0].textContent;
            mapDataEntry.latitude = row.cells[1].textContent;
            mapDataEntry.longitude = row.cells[2].textContent;
            mapDataEntry.radius = Number(row.cells[3].textContent);
            mapDataEntry.scale = Number(row.cells[4].textContent);
            mapDataEntry.borderColor = row.cells[5].textContent;
            mapDataEntry.fillColor = row.cells[6].textContent;
            mapDataEntry.clickable = Boolean(row.cells[7].textContent);
            mapDataEntry.imageUri = row.cells[8].textContent;

            var layers = row.cells[9].textContent;

            if (layers !== "")
                mapDataEntry.layers = layers.split(",");
            else
                mapDataEntry.layers = [];

            if (row.cells[10].childNodes.length > 0)
                mapDataEntry.information = row.cells[10].childNodes[0];

            mapDataEntries.push(mapDataEntry);
            this.markers.push(this.createMarkerFromDataMapEntry(mapDataEntry));
        }
    }

    this.createMarkerFromDataMapEntry = function (dataMapEntry) {
        var icon;

        if (dataMapEntry.imageUri !== "")
            icon = dataMapEntry.imageUri;
        else
            icon = {
                path: google.maps.SymbolPath.CIRCLE,
                radius: dataMapEntry.radius,
                scale: dataMapEntry.scale,
                strokeColor: dataMapEntry.borderColor,
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: dataMapEntry.fillColor,
                fillOpacity: 0.5
            };

        var marker = new google.maps.Marker({
            clickable: dataMapEntry.clickable,
            position: new google.maps.LatLng(dataMapEntry.latitude, dataMapEntry.longitude),
            icon: icon,
            title: dataMapEntry.title
        });

        marker.layers = dataMapEntry.layers;

        if (this.informationWindow !== null) {
            var object = this;

            google.maps.event.addListener(
                marker,
                "click",
                function() {
                    if (typeof dataMapEntry.information != "undefined") {
                        object.showInformation.setStatus(true);
                        object.showInformationToggle.toggle();

                        var query = new DomQuery(object.informationWindow);
                        var content = query.getDescendant(WithClass("Content"));

                        content.replaceChild(dataMapEntry.information, content.firstChild);
                    }
                    else
                        object.showInformation.setStatus(false);
                }
            );
        }

        return marker;
    }

    this.drawMarkersWithClustering = function () {
        if (this.markers.length > 0)
            new MarkerClusterer(
                this.map,
                this.markers,
                {
                    maxZoom: 6,
                    gridSize: 50,
                    imagePath: "https://googlemaps.github.io/js-marker-clusterer/images/m"
                }
            );
    }

    this.drawMarkers = function () {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(this.map);
        }
    }

    this.drawMarkersWithLayers = function (layers) {
        for (var i = 0; i < this.markers.length; i++) {
            var marker = this.markers[i];
            var visible = true;
            var j = 0;

            while (visible && j < marker.layers.length) {
              visible = layers.indexOf(marker.layers[j]) != -1;
              j++;
            }

            marker.setVisible(visible);
        }
    }

    this.createMarker = function (title, latitude, longitude, radius, scale, strokeColor, fillColor, clickable) {
        return new google.maps.Marker({
            clickable: clickable,
            position: new google.maps.LatLng(latitude, longitude),
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                radius: radius,
                scale: scale,
                strokeColor: strokeColor,
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: fillColor,
                fillOpacity: 0.5
            },
            title: title
        });
    }

    this.scheduleAddressFieldChanged = function (operation) {
        this.updateMapTimer.kill();
        this.updateMapTimer.schedule(operation);
    }

    this.attachEventHandlers = function () {
        var self = this;

        if (this.addressField != null) {
            this.addressField.oninput = function () {
                self.scheduleAddressFieldChanged(
                    function() {
                        self.handleAddressFieldChanged();
                    }
                );
            }
        }
    }

    this.handleAddressFieldChanged = function () {
        if (this.addressField.value !== "") {
            this.address = this.addressField.value;
            this.outstandingRequest = true;
            var self = this;

            this.geocoder.geocode({"address": this.addressField.value}, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    geometry = results[0].geometry;

                    self.map.fitBounds(geometry.viewport);
                    self.handleNewLocation(geometry.location);
                    self.outstandingRequest = false;
                }
            });
        }
    }

    this.checkSubmitReady = function () {
      return !this.outstandingRequest;
    }

    this.handleNewLocation = function(location_latLng) {
        this.dropLocationMarker(location_latLng);
        this.map.setCenter(location_latLng);

        if (this.latitudeField != null)
            this.latitudeField.value = location_latLng.lat();

        if (this.longitudeField != null)
            this.longitudeField.value = location_latLng.lng();

        if (this.map.zoom > 5) {
            this.drawMarkersWithClustering();
        }
    }

    this.initialize = function () {
        if (this.mapDataObject === "location" || this.mapDataObject === "terminal") {
            if (this.mode === ControlMode.display)
                this.showLocation();
            else if (this.mode === ControlMode.edit)
                this.drawMapWithLocation(this.findLocation());
        }
        else if (this.mapDataObject === "route")
            this.showRoute();
        else if (this.mapDataObject === "catchment")
            this.drawCatchmentArea();
    }

    this.showLocation = function () {
        var location_latLng = new google.maps.LatLng(this.latitude, this.longitude);

        var mapOptions = {
            zoom: 12,
            center: location_latLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true
        }

        this.map = new google.maps.Map(this.canvas, mapOptions);
        this.createMarker(this.address, this.latitude, this.longitude, 20, 10, "#1957A3", "#1957A3", false).setMap(this.map);

        if (this.map.zoom > 5) {
            this.drawMarkersWithClustering();
        }
    }

    this.drawCatchmentArea = function () {
        var location_latLng = new google.maps.LatLng(this.latitude, this.longitude);

        var mapOptions = {
            zoom: Number(this.zoomLevel),
            center: location_latLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true
        }

        this.map = new google.maps.Map(this.canvas, mapOptions);
        this.drawMarkers();
    }

    this.drawLine = function(fromLatitude, fromLongitude, toLatitude, toLongitude) {
        var lineCoordinates = [
            new google.maps.LatLng(fromLatitude, fromLongitude),
            new google.maps.LatLng(toLatitude, toLongitude)
        ];

        var line = new google.maps.Polyline({
            path: lineCoordinates,
            strokeColor: "#00A0C0",
            map: this.map
        });
    }

    this.showRoute = function () {
        var location_latLng = new google.maps.LatLng(this.latitude, this.longitude);

        var mapOptions = {
            zoom: 5,
            center: location_latLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true
        }

        this.map = new google.maps.Map(this.canvas, mapOptions);
        this.directionsService = new google.maps.DirectionsService();

        var directionsRendererOptions = {
            suppressMarkers: true,
            preserveViewport: true
        }

        this.originDirectionsDisplay = new google.maps.DirectionsRenderer(directionsRendererOptions);
        this.originDirectionsDisplay.setMap(this.map);

        this.destinationDirectionsDisplay = new google.maps.DirectionsRenderer(directionsRendererOptions);
        this.destinationDirectionsDisplay.setMap(this.map);

        this.drawRoute();
    }

    this.findLocation = function () {
        if (
            (this.latitudeField != null)
            && (this.longitudeField != null)
            && (this.latitudeField.value != "")
            && (this.longitudeField.value != "")
        ) {
            this.latitude = this.latitudeField.value;
            this.longitude = this.longitudeField.value;
        }

        var location_latLng = new google.maps.LatLng(this.latitude, this.longitude);

        if ((this.latitude == "") || (this.longitude == ""))
            location_latLng = new google.maps.LatLng(51.95, 4.56);

        return location_latLng;
    }

    this.drawMapWithLocation = function (location_latLng) {
        var self = this;

        var mapOptions = {
            zoom: Number(this.zoomLevel),
            center: location_latLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true
        };

        this.map = new google.maps.Map(this.canvas, mapOptions);

        if (!this.hideMarker) {
            this.dropLocationMarker(location_latLng);
        }

        if (this.map.zoom > 5) {
            this.drawMarkersWithClustering();
        }

        google.maps.event.addListener(this.map, "click", function (event) { self.handleClick(event); });

        if ((this.latitude == "" || this.longitude == "") && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    var current_location_latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

                    self.handleNewLocation(current_location_latLng);
                }
            );
        }
    }

    this.handleClick = function (event) {
        if (this.latitudeField != null)
            this.latitudeField.value = event.latLng.lat();

        if (this.longitudeField != null)
            this.longitudeField.value = event.latLng.lng();

        this.findAddressForCoordinates(event.latLng);
        this.dropLocationMarker(event.latLng);

        if (this.map.zoom > 5) {
            this.drawMarkersWithClustering();
        }
    }

    this.dropLocationMarker = function (location_latLng) {
        if (this.locationMarker == null) {
            this.locationMarker = new google.maps.Marker({
                position: location_latLng,
                map: this.map,
                title: this.address
            });
        }
        else {
            this.locationMarker.setPosition(location_latLng);
            this.locationMarker.setTitle(this.address);
        }
    }

    this.findAddressForCoordinates = function (location_latLng) {
        var self = this;
        this.outstandingRequest = true;

        this.geocoder.geocode({"latLng": location_latLng}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                if (results[1]) {
                    if (self.addressField != null)
                        self.handleNewAddress(results[1].formatted_address);
                }
                self.outstandingRequest = false;
            }
        });
    }

    this.handleNewAddress = function (address) {
        if (this.addressField != null)
            this.addressField.value = address;

        this.address = address;
    };

    this.drawRoute = function () {
        if (this.markers.length > 0) {
            var self = this;

            var marker = this.markers[0];
            marker.setMap(this.map);

            var previousLatLng = marker.getPosition();
            var mapBounds = new google.maps.LatLngBounds(previousLatLng, previousLatLng);

            marker = this.markers[1];
            var request = {
                origin: previousLatLng,
                destination: marker.getPosition(),
                travelMode: google.maps.DirectionsTravelMode.DRIVING
            };

            this.directionsService.route(request, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    self.originDirectionsDisplay.setDirections(response);

                    var distanceText = response.routes[0].legs[0].distance.text;
                    document.getElementsByClassName("PreHaulageValue")[0].innerHTML = distanceText;

                    var pdfLink = document.getElementsByClassName("RoutePDF")[0];

                    if (typeof pdfLink !== "undefined")
                      pdfLink.href = new Uri(pdfLink.href).addQueryParameter("PreHaulageDistance", distanceText);
                }
            });

            marker.setMap(this.map);

            var previousLatitude = marker.getPosition().lat();
            var previousLongitude = marker.getPosition().lng();

            mapBounds.extend(new google.maps.LatLng(previousLatitude, previousLongitude));

            for (var i = 2; i < this.markers.length - 1; i++) {
                marker = this.markers[i];

                this.drawLine(
                    previousLatitude, previousLongitude,
                    marker.getPosition().lat(), marker.getPosition().lng()
                );

                marker.setMap(this.map);

                previousLatitude = marker.getPosition().lat();
                previousLongitude = marker.getPosition().lng();

                mapBounds.extend(new google.maps.LatLng(previousLatitude, previousLongitude));
            }

            marker = this.markers[this.markers.length - 1];
            marker.setMap(this.map);

            var request = {
                origin: new google.maps.LatLng(previousLatitude, previousLongitude),
                destination: marker.getPosition(),
                travelMode: google.maps.DirectionsTravelMode.DRIVING
            };

            this.directionsService.route(request, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    self.destinationDirectionsDisplay.setDirections(response);

                    var distanceText = response.routes[0].legs[0].distance.text;
                    document.getElementsByClassName("PostHaulageValue")[0].innerHTML = distanceText;

                    var pdfLink = document.getElementsByClassName("RoutePDF")[0];

                    if (typeof pdfLink !== "undefined")
                      pdfLink.href = new Uri(pdfLink.href).addQueryParameter("PostHaulageDistance", distanceText);
                }
            });

            this.map.fitBounds(mapBounds);
        }
    }

    this.latitudeField = null;
    this.longitudeField = null;
    this.addressField = null;
    this.canvas = null;
    this.mapData = null;
    this.markers = [];

    this.map = null;
    this.legend = null;
    this.informationWindow = null;
    this.showInformation = null;
    this.locationMarker = null;
    this.geocoder = new google.maps.Geocoder();
    this.directionsService = null;
    this.originDirectionsDisplay = null;
    this.destinationDirectionsDisplay = null;

    this.mapDataObject = this.element.dataset.MapDataObject;
    this.latitude = this.element.dataset.Latitude;
    this.longitude = this.element.dataset.Longitude;
    this.address = this.element.dataset.Address;
    this.zoomLevel = this.element.dataset.ZoomLevel;
    this.hideMarker = this.element.dataset.HideMarker;

    this.outstandingRequests = 0;

    this.determineElements();
    this.attachEventHandlers();
    this.updateMapTimer = new Timer(1000);
    this.initialize();
}

interactivityRegistration.register("googleMaps", function (element) { return new GoogleMapsField(element); });

function ContextMenu(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function () {
        this.viewPort.addEventListener("scroll", this.createScrollHandler())
    }

    this.createScrollHandler = function () {
        var contextMenu = this;

        return function (event) { contextMenu.handleScroll(); }
    }

    this.determineElements = function () {
        this.items = new Array();
        this.nodes = new DomQuery(this.element).getDescendants(WithTagName("LI"));

        var items = new DomQuery(this.element).getDescendants(WithTagName("A"));

        for (var item of items)
            this.items.push(new ContextMenuItem(item.parentElement.parentElement, item, this.viewPort));

        this.items.sort(function (left, right) {
            if (left.reference.offsetTop > right.reference.offsetTop)
                return -1
            else if (left.reference.offsetTop < right.reference.offsetTop)
                return 1
            else
                return 0;
        })
    }

    this.handleScroll = function () {
        var result = false;
        var active = null;

        for (var item of this.items) {
            if (item.isActive() & !result) {
                result = true;
                item.element.classList.add("Active");

                active = item;
            }
            else
                item.element.classList.remove("Active");
        }

        if (active !== null) {
            var viewPort = this.element.childNodes[0];
            
            if (active.item.offsetTop < viewPort.scrollTop || active.item.offsetTop > (viewPort.scrollTop + viewPort.offsetHeight))
                viewPort.scrollTop = active.item.offsetTop;
            
            var query = new DomQuery(active.element);

            for (var node of this.nodes) {
                if (!node.parentElement.classList.contains("leavesOnly")) {
                    node.classList.remove("expanded");
                    node.classList.remove("collapsed");

                    if (query.hasAncestor(node))
                        node.classList.add("expanded");
                    else
                        node.classList.add("collapsed")
                }
            }
        }
    }

    this.viewPort = element.parentElement.childNodes[0];

    this.determineElements();
    this.attachHandlers();
    this.handleScroll();
}

function ContextMenuItem(element, item, viewPort) {
    WebPageComponent.call(this, element);

    this.isActive = function () {
        if (this.reference !== null)
            return this.viewPort.scrollTop >= (this.reference.offsetTop - 10);
        else
            return false;
    }

    this.item = item;
    this.id = this.item.text;
    this.viewPort = viewPort;
    this.reference = new DomQuery(document).getDescendant(WithId(this.id));
}

interactivityRegistration.register("ContextMenu", function (element) { return new ContextMenu(element); });